import React from 'react'

export default () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Vector"
      d="M7 14C3.1339 14 0 10.8661 0 7C0 3.1339 3.1339 0 7 0C10.8661 0 14 3.1339 14 7C14 10.8661 10.8661 14 7 14ZM11.4485 9.7664V9.6929C11.4485 9.0475 11.4485 8.7528 10.9921 8.4917C10.8454 8.40854 10.6947 8.33261 10.5406 8.2642C10.2837 8.1473 10.1136 8.071 9.884 7.735C9.85659 7.6951 9.82952 7.65497 9.8028 7.6146C9.5613 7.2471 9.387 6.9825 8.7234 7.0875C7.4179 7.2947 7.3213 7.5243 7.2639 7.9121L7.2548 7.9758C7.1701 8.5428 7.1547 8.7332 7.3913 8.9817C8.2768 9.9106 8.8074 10.5805 8.9684 10.9725C9.0468 11.1636 9.2484 11.7425 9.1098 12.3151C9.95948 11.9767 10.7158 11.4397 11.3155 10.7492C11.3925 10.4874 11.4485 10.1612 11.4485 9.7664ZM7 1.2831C5.3781 1.2831 3.913 1.9593 2.8728 3.0443C2.9967 3.1304 3.1045 3.2515 3.1787 3.4181C3.3215 3.738 3.3215 4.0677 3.3215 4.3596C3.3215 4.5892 3.3215 4.8076 3.395 4.9651C3.4958 5.1807 3.9312 5.2731 4.3155 5.3529C4.4534 5.3823 4.5948 5.4117 4.7236 5.4474C5.0778 5.5454 5.3522 5.8639 5.5713 6.1194C5.6623 6.2251 5.7974 6.3812 5.8653 6.4204C5.9003 6.3952 6.013 6.2727 6.0683 6.0718C6.1117 5.9178 6.0991 5.782 6.0368 5.7078C5.6448 5.2458 5.6665 4.3568 5.7876 4.0285C5.978 3.5112 6.573 3.5497 7.0084 3.5777C7.1708 3.5882 7.3234 3.5987 7.4382 3.584C7.8736 3.5294 8.008 2.8665 8.1025 2.737C8.3069 2.457 8.9327 2.0349 9.3205 1.7745C8.59005 1.44969 7.79941 1.28227 7 1.2831Z"
      fill="#0D1333"
    />
  </svg>
)
