import React, { FC, useMemo } from 'react'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { Box, LinearProgress, Typography } from '@mui/material'

interface FooterProps {
  // eslint-disable-next-line react/require-default-props
  loading?: boolean
  onNext: () => void
  onSubmit: () => void
  currentStep: number
  allStep: number
  disabled: boolean
}
const FormV4Footer: FC<FooterProps> = props => {
  const { onNext, onSubmit, currentStep, allStep, disabled, loading = false } = props
  const isLastStep = useMemo(() => currentStep === allStep, [currentStep, allStep])
  const onClick = () => {
    if (disabled) {
      return
    }
    if (isLastStep) {
      onSubmit()
    } else {
      onNext()
    }
  }
  return (
    <Box className="fixed flex justify-between items-center w-full bottom-0 py-4 px-5 bg-white shadow-footerShadow">
      <Box className="flex flex-col justify-start items-start">
        <span className="text-lg font-Averta7">
          {currentStep}/{allStep}
        </span>
        <LinearProgress className="w-[170px] my-2.5" variant="determinate" value={Number((currentStep * 100) / allStep)} />
      </Box>
      <LoadingButton loading={loading} disabled={disabled} onClick={onClick} className="!rounded-xl !py-3 !px-10" variant="contained">
        <Typography className="!text-base font-Averta6 font-semibold  capitalize">{isLastStep ? 'Submit' : 'Next'}</Typography>
      </LoadingButton>
    </Box>
  )
}

export default FormV4Footer
