import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import { Statsig } from 'statsig-react'

import NoUnitImg from '../../../assets/imgs/noUnit.png'
import SearchUnitImg from '../../../assets/imgs/searchUnit.png'
import { QUESTIONNAIRE_NOTIFY_GPT } from '../../../graphqls/mutations'
import { QUERY_EXIST_UNIT_BY_QUESTIONNAIRE_ID } from '../../../graphqls/queries'
import { checkIsMobile } from '../../../utils/agent'

interface SubmitResultProps {
  questionnaireId: string
  // eslint-disable-next-line react/require-default-props
  isPC?: boolean
}
const isMobile = checkIsMobile()

const SubmitResult: FC<SubmitResultProps> = props => {
  const [searchParams] = useSearchParams()

  const channel = searchParams.get('channel')
  const version = searchParams.get('version')

  const { questionnaireId, isPC = false } = props
  const [haveUnit, setHaveUnit] = useState(true)
  const navigateTo = useNavigate()
  useEffect(() => {
    Statsig.logEvent('survey_v4_submit_page', window.location.href, {
      isPCUI: isPC.toString(),
      isMobile: isMobile.toString(),
      questionnaireId,
    })
  }, [])

  const [questionnaireNotifyGpt] = useMutation(QUESTIONNAIRE_NOTIFY_GPT)

  useQuery(QUERY_EXIST_UNIT_BY_QUESTIONNAIRE_ID, {
    fetchPolicy: 'cache-and-network',
    variables: {
      questionnaireId,
      channel: Number(channel),
    },
    onCompleted(data) {
      if (data) {
        const { existUnitByQuestionnaireId } = data || {}
        const { isExist = false } = existUnitByQuestionnaireId || {}
        setHaveUnit(isExist)
        if (isExist) {
          Statsig.logEvent('survey_v4_submit_page_jump_tour', window.location.href, { isExist: isExist.toString(), questionnaireId })
          navigateTo(`/scheduleTour/${questionnaireId}?version=${version}`, { replace: true })
        } else {
          questionnaireNotifyGpt({
            variables: {
              input: {
                questionnaireId,
              },
            },
          })
        }
      } else {
        setHaveUnit(false)
      }
    },
    onError() {
      setHaveUnit(false)
    },
  })
  return (
    <Box className="w-full !px-5 !py-[88px] flex flex-col justify-center items-center">
      {haveUnit ? (
        <>
          <img className="w-20 h-20" src={SearchUnitImg} alt="no unit" />
          <Typography className="!mt-4 !font-Averta6 !text-lg">We&apos;re searching units for you...</Typography>
        </>
      ) : (
        <>
          <img className="w-20 h-20" src={NoUnitImg} alt="have unit" />
          <Typography className={`!mt-4 !font-Averta6 ${isPC ? '!text-2xl' : '!text-lg'}`}>Got it, thanks!</Typography>
          <Typography className={`!mt-2 !font-Averta4 ${isPC ? '!text-base' : '!text-sm'} !text-[#75798D]`}>
            Our leasing team will contact you soon.
          </Typography>
        </>
      )}
    </Box>
  )
}

export default SubmitResult
