import React, { useContext, useEffect, useMemo } from 'react'

import contextData from '../contextData'

const LeaseTermHooks = (currentIndex: number) => {
  const { moduleList, dataCollect, mergeDataCollectFunction } = useContext(contextData)
  const currentData = moduleList[currentIndex]
  const leaseTerm = (useMemo(() => dataCollect?.[currentIndex]?.submitValue?.[0]?.value, [dataCollect]) as number) || 0
  const isCanNext = useMemo(() => leaseTerm > 0, [leaseTerm])
  const displayData = useMemo(() => `${leaseTerm} months`, [leaseTerm])

  const onChange = (value: number) => {
    const submitValue = [{ name: dataCollect[currentIndex]?.submitValue?.[0].name, value: value || null }]
    if (mergeDataCollectFunction) {
      mergeDataCollectFunction(currentIndex, { submitValue })
    }
  }

  useEffect(() => {
    if (mergeDataCollectFunction) {
      mergeDataCollectFunction(currentIndex, {
        isCanNext,
        displayData,
      })
    }
  }, [isCanNext, displayData])

  return {
    currentData,
    leaseTerm,
    onChange,
  }
}

export default LeaseTermHooks
