import React, { FC, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Alert, Box, LinearProgress, useMediaQuery } from '@mui/material'
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import { Statsig } from 'statsig-react'

import { SAVE_QUESTIONNAIRE, SAVE_URL_TTL_LOG } from '../../../graphqls/mutations'
import { QUERY_PREFERENCE_BY_QUESTIONNAIRE_ID, QUESTIONNAIRE_CHECK } from '../../../graphqls/queries'
import { ModuleListItemType } from '../../../types/form.d'
import { initPageConfig } from '../../../utils/index'
import { changeAnswerArrToStr } from './batchAnswer'
import BudgetV5 from './budgetV5'
import FilledForm from './filledForm'
import FloorPlanV5 from './floorplanV5'
import Footer from './footer'
import Header from './header'
import LeaseTermV5 from './leaseTermV5'
import MoveDateV5 from './moveDateV5'
import PCV5 from './PC'
import SubmitResult from './submitResult'

// after add custom theme, the mui will cover css, need to add ! before class
const theme: Theme = createTheme({
  palette: {
    secondary: {
      main: '#050A22',
    },
    primary: {
      main: '#E74F4F',
    },
  },
})
let logInterval: NodeJS.Timer | null = null

const calcDefaultCreditScoreType = (type: string) => {
  if (type === 'GREATER_EQ') {
    return '0'
  }
  if (type === 'LESS') {
    return '1'
  }
  if (type === 'UNKNOWN') {
    return '2'
  }
  return ''
}

const FormV5: FC = () => {
  const [searchParams] = useSearchParams({ id: '', sign: '' })
  const id = searchParams.get('id')
  const sign = searchParams.get('sign')
  const version = searchParams.get('version')
  const isPCUI = useMediaQuery('(min-width:512px)')
  const allStep = 4
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [isSubmit, setIsSubmit] = useState(false)
  const [creditNumber, setCreditScoreNumber] = useState<number>(675)

  const [budget, setBudget] = useState<[number, number]>([0, 0])
  const [moveInDate, setMoveInDate] = useState({
    from: '',
    to: '',
  })
  const [guarantee, setGuarantee] = useState('')
  const [creditScore, setCreditScore] = useState('')
  const [isStudent, setIsStudent] = useState(false)
  const [leaseTerm, setLeaseTerm] = useState(12)
  const [bedroomList, setBedroomList] = useState<{ key: string; value: string }[]>([])
  const [bathroomList, setBathroomList] = useState<{ key: string; value: string }[]>([])
  const [selectBedroom, setSelectBedroom] = useState<string[]>([])
  const [selectBathroom, setSelectBathroom] = useState<string[]>([])

  const [saveUrlTtlLogApi] = useMutation(SAVE_URL_TTL_LOG, {
    variables: { input: { questionnaireId: id, source: 'QUESTIONNAIRE' } },
    onError() {
      // nothing
    },
  })

  const [fetchPreference, { loading: loadingPreference }] = useLazyQuery(QUERY_PREFERENCE_BY_QUESTIONNAIRE_ID, {
    variables: {
      questionnaireId: id,
    },
    onCompleted(data) {
      const { queryPreferenceByQuestionnaireId } = data || {}
      const {
        leaseTerm: defaultLeaseTerm,
        moveInDateBegin,
        moveInDateEnd,
        isStudent: defaultIsStudent,
        creditScoreType,
        cosigner,
        budgetMin,
        budgetMax,
        bedRoomQuantity,
        bathRoomQuantity,
      } = queryPreferenceByQuestionnaireId || {}
      const answers = [
        Boolean(bedRoomQuantity),
        Boolean(budgetMax),
        Boolean(moveInDateBegin),
        Boolean(defaultLeaseTerm),
        Boolean(creditScoreType),
      ]
      const emptyIndex = answers.findIndex(item => !item)
      const currentUnSubmit = emptyIndex > -1

      if (currentUnSubmit) {
        if (logInterval) {
          clearInterval(logInterval)
        }
        saveUrlTtlLogApi()
        logInterval = setInterval(saveUrlTtlLogApi, 10000)
      }
      setIsSubmit(!currentUnSubmit)
      setCurrentStep(emptyIndex + 1)

      setSelectBedroom(bedRoomQuantity || [])
      setSelectBathroom(bedRoomQuantity && bathRoomQuantity ? bedRoomQuantity : [])
      setBudget([budgetMin || 0, budgetMax || 0])
      setMoveInDate({
        from: moveInDateBegin ? moment(moveInDateBegin).format('YYYY-MM-DD') : '',
        to: moveInDateEnd ? moment(moveInDateEnd).format('YYYY-MM-DD') : '',
      })
      setLeaseTerm(defaultLeaseTerm || 12)
      setCreditScore(calcDefaultCreditScoreType(creditScoreType))
      setIsStudent(defaultIsStudent)
      setGuarantee(cosigner === null ? '' : cosigner ? '0' : '1')
    },
    onError() {
      Statsig.logEvent('survey_v5_fetch_preference_error', window.location.href, {
        questionnaireId: id,
      })
    },
  })

  const [fetchQuestionnaireCheck, { loading }] = useLazyQuery(QUESTIONNAIRE_CHECK, {
    variables: {
      request: { id, sign },
    },
    onCompleted: data => {
      const { score: resScore, bedroom, bathroom } = data.questionnaireCheck || {}
      setBedroomList(bedroom || [])
      setBathroomList(bathroom || [])
      setCreditScoreNumber(Number(resScore))
      fetchPreference()
    },
    onError() {
      // nothing
    },
  })

  const [saveQuestion, { loading: saveLoading }] = useMutation(SAVE_QUESTIONNAIRE)

  const floorPlanDisplayData = () => {
    let str = ''
    const tempBedroomList = bedroomList.filter(item => selectBedroom.includes(item.key))
    const tempBathroomList = bathroomList.filter(item => selectBathroom.includes(item.key))
    str = `${tempBedroomList.map(i => i.value).join(',')} Bed;`
    if (tempBathroomList.length > 0) {
      str += ` ${tempBathroomList.map(i => i.value).join(',')} Bath;`
    }

    return str
  }

  const mobileModuleList: Omit<ModuleListItemType, 'data'>[] = [
    {
      component: (
        <FloorPlanV5
          bathroomList={bathroomList}
          bedroomList={bedroomList}
          selectBedroom={selectBedroom}
          selectBathroom={selectBathroom}
          onChange={(type, list) => {
            if (type === 'bedroom') {
              setSelectBedroom(list)
            } else if (type === 'bathroom') {
              setSelectBathroom(list)
            }
          }}
        />
      ),
      label: 'Floor plan',
      displayData: floorPlanDisplayData(),
      key: 'floor-plan',
    },
    {
      component: <BudgetV5 budget={budget} onChange={setBudget} />,
      label: 'Budget',
      displayData: `$${budget[0]} - $${budget[1]}`,
      key: 'budget',
    },
    {
      component: <MoveDateV5 moveInDate={moveInDate} onChange={setMoveInDate} />,
      label: 'Move-in Date',
      displayData: `${moment(moveInDate.from).format('ll')}`,
      key: 'move-in-Date',
    },
    {
      component: <LeaseTermV5 leaseTerm={leaseTerm} onChange={setLeaseTerm} />,
      label: 'Lease Term',
      displayData: `${leaseTerm} ${leaseTerm === 1 ? 'month' : 'months'}`,
      key: 'lease-term',
    },
    // {
    //   component: (
    //     <CreditScoreV5
    //       isPC={isPCUI}
    //       creditNumber={creditNumber}
    //       guarantee={guarantee}
    //       onChangeGuarantee={setGuarantee}
    //       isStudent={isStudent}
    //       creditScore={creditScore}
    //       onChangeScore={setCreditScore}
    //       onChangeIsStudent={setIsStudent}
    //     />
    //   ),
    //   label: 'Credit score',
    //   displayData: `_`,
    //   key: 'credit-score',
    // },
  ]
  const showHeaderBack = useMemo(() => {
    if (isSubmit) return false
    return currentStep > 1 && currentStep <= allStep
  }, [currentStep, allStep, isSubmit])

  const footerCanNext = useMemo(() => {
    if (currentStep === 1) {
      return selectBedroom.length > 0
    }
    if (currentStep === 2) {
      return budget.some(p => p !== 0)
    }
    if (currentStep === 3) {
      return moveInDate.from !== ''
    }
    if (currentStep === 4) {
      return leaseTerm > 0
    }
    if (currentStep === 5) {
      return creditScore !== ''
    }

    return false
  }, [currentStep, selectBedroom, budget, moveInDate, guarantee, creditScore])
  const pcCanSubmit = useMemo(
    () => selectBedroom.length > 0 && budget.some(p => p !== 0) && moveInDate.from !== '' && leaseTerm > 0,
    [selectBedroom, budget, moveInDate, leaseTerm, guarantee, creditScore],
  )
  const onNextOrSubmit = (submit: boolean) => {
    const answers = [
      [selectBedroom, selectBathroom],
      budget,
      moveInDate,
      leaseTerm,
      { creditScore: creditScore || 0, isStudent },
      { creditScore, guarantee },
    ]

    const strAnswers = changeAnswerArrToStr(answers)
    const stepAnswers = isPCUI || currentStep === allStep ? strAnswers : strAnswers.slice(0, currentStep)
    const request = {
      ...Object.fromEntries(searchParams),
      id,
      sign,
      version: Number(version),
      answer: stepAnswers,
      step: '5',
    }
    Statsig.logEvent('survey_v5_next_or_submit', window.location.href, {
      ...(request as unknown as Record<string, string>),
      isSubmit: isSubmit.toString(),
      isPCUI: isPCUI.toString(),
    })
    saveQuestion({
      variables: {
        request,
      },
      onCompleted: () => {
        if (submit) {
          setIsSubmit(true)
          if (logInterval) {
            clearInterval(logInterval)
          }
        } else if (!isPCUI) {
          setCurrentStep(() => currentStep + 1)
        }
      },
      onError() {
        if (!isPCUI && !submit) {
          setCurrentStep(() => currentStep + 1)
        }
      },
    })
  }
  const checkLogInterval = () => {
    if (document.visibilityState === 'hidden' && logInterval) {
      clearInterval(logInterval)
    }
    if (document.visibilityState === 'visible') {
      saveUrlTtlLogApi()
      if (logInterval) clearInterval(logInterval)
      logInterval = setInterval(saveUrlTtlLogApi, 10000)
    }
  }

  useEffect(() => {
    initPageConfig(5)

    if (id && sign) {
      Statsig.logEvent('survey_v5_PV_UV', window.location.href, { id, sign })
      fetchQuestionnaireCheck()
    }
    document.addEventListener('visibilitychange', checkLogInterval)
    return () => {
      if (logInterval) clearInterval(logInterval)
      document.removeEventListener('visibilitychange', checkLogInterval)
    }
  }, [])
  useEffect(() => {
    if (isPCUI) return
    Statsig.logEvent('survey_v5_mobile_page', window.location.href, { id, sign, step: (currentStep - 1).toString() })
  }, [currentStep])
  if (!id || !sign) {
    const message = !id ? 'id' : 'sign'
    return (
      <Alert severity="error" elevation={6} variant="filled">
        This is an incorrect link! {`(${message} missing)`}
      </Alert>
    )
  }
  if (loadingPreference || loading) {
    return (
      <ThemeProvider theme={theme}>
        <LinearProgress color="primary" />
      </ThemeProvider>
    )
  }
  return (
    <ThemeProvider theme={theme}>
      {isPCUI ? (
        <PCV5
          moduleList={mobileModuleList}
          loading={saveLoading}
          disabled={!pcCanSubmit}
          onSubmit={() => onNextOrSubmit(true)}
          isSubmit={isSubmit}
          questionnaireId={id}
        />
      ) : (
        <>
          <Header
            onBack={() => {
              setCurrentStep(() => currentStep - 1)
            }}
            showBack={showHeaderBack}
          />
          <Box className="pt-12 pb-[95px]">
            {!isSubmit && (
              <>
                <FilledForm moduleList={mobileModuleList} onBackToStep={setCurrentStep} currentStep={currentStep} />
                {mobileModuleList[currentStep - 1].component}
              </>
            )}
            {isSubmit && <SubmitResult questionnaireId={id} />}
          </Box>
          {!isSubmit && (
            <Footer
              currentStep={currentStep}
              allStep={allStep}
              onNext={() => onNextOrSubmit(false)}
              onSubmit={() => onNextOrSubmit(true)}
              loading={saveLoading}
              disabled={!footerCanNext}
            />
          )}
        </>
      )}
    </ThemeProvider>
  )
}

export default FormV5
