import React, { useContext, useEffect, useMemo } from 'react'

import contextData from '../contextData'
import { MODULES_CODE_ENUM, QuestionItemProps } from '../types.d'

const StayPlaningHooks = (currentIndex: number) => {
  const { moduleList, isPc, dataCollect, mergeDataCollectFunction, updateDataCollectSubmitValueFunction } = useContext(contextData)
  const currentData = moduleList[currentIndex]
  const stayPlanType = (useMemo(() => dataCollect?.[currentIndex]?.submitValue?.[0]?.value, [dataCollect]) as string) || ''
  const leaseTerm = (useMemo(() => dataCollect?.[currentIndex]?.submitValue?.[1]?.value, [dataCollect]) as number) || 0
  const isCanNext = useMemo(
    () => (stayPlanType === MODULES_CODE_ENUM.CUSTOM_MONTHS ? leaseTerm > 0 : Boolean(stayPlanType)),
    [stayPlanType, leaseTerm],
  )
  const displayData = useMemo(() => {
    const stayPlanTypeText = currentData?.question?.stayPlan?.find((item: { key: string }) => item?.key === stayPlanType)?.desc || ''
    return `${stayPlanTypeText || ''}${leaseTerm > 0 && stayPlanType === MODULES_CODE_ENUM.CUSTOM_MONTHS ? `-${leaseTerm} months` : ''}`
  }, [leaseTerm, stayPlanType])

  const showChildrenKey: string[] = useMemo(
    () =>
      (currentData?.question?.stayPlan.find((item: QuestionItemProps) => item?.key === stayPlanType) || { childrenKey: [] })?.childrenKey,
    [stayPlanType],
  )

  const onStayPlanTypeChange = (val: string) => {
    if (val === stayPlanType) return

    if (val !== MODULES_CODE_ENUM.CUSTOM_MONTHS && mergeDataCollectFunction) {
      const submitValue = [
        {
          name: dataCollect?.[currentIndex]?.submitValue?.[0]?.name,
          value: val,
        },
        {
          name: dataCollect?.[currentIndex]?.submitValue?.[1]?.name,
          value: null,
        },
      ]
      mergeDataCollectFunction(currentIndex, {
        submitValue,
      })
      return
    }
    if (updateDataCollectSubmitValueFunction) {
      updateDataCollectSubmitValueFunction(currentIndex, 0, val)
    }
  }

  const onLeaseTermChange = (val: number) => {
    if (updateDataCollectSubmitValueFunction) {
      updateDataCollectSubmitValueFunction(currentIndex, 1, val)
    }
  }

  useEffect(() => {
    if (mergeDataCollectFunction) {
      mergeDataCollectFunction(currentIndex, {
        isCanNext,
        displayData,
      })
    }
  }, [isCanNext, displayData])

  return {
    isPc,
    showChildrenKey,
    currentData,
    leaseTerm,
    stayPlanType,
    onStayPlanTypeChange,
    onLeaseTermChange,
  }
}

export default StayPlaningHooks
