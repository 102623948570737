const floorPlanArrToStr = (floorPlan: [string[], string[]]) => {
  const bedrooms = floorPlan[0]
  const bathrooms = floorPlan[1]
  const bedroomsStr = bedrooms.length > 0 ? JSON.stringify(bedrooms) : 'NULL'
  const bathroomsStr = bathrooms.length > 0 ? JSON.stringify(bathrooms) : 'NULL'
  return `${bedroomsStr},${bathroomsStr}`
}

const budgetArrToStr = (budget: [number, number]) => budget.toString()

const moveInDateArrToStr = (moveInDate: { from: string; to: string }) => `${moveInDate.from || ''},${moveInDate.to || ''}`

const leaseTermArrToStr = (data: number) => data.toString()

const creditScoreArrToStr = (result: { creditScore: string; isStudent: boolean }) =>
  `${result.creditScore === '1' ? `${result.creditScore},${result.isStudent}` : result.creditScore}`

const guaranteeArrToStr = (result: { creditScore: string; guarantee: string }) => {
  if (result.creditScore === '0' || result.guarantee === '') return 'NULL'
  return result.guarantee
}

// api order returns: floor-plan budget moveInDate leadsTerm creditScore guarantee
const arrToStrList = [floorPlanArrToStr, budgetArrToStr, moveInDateArrToStr, leaseTermArrToStr, creditScoreArrToStr, guaranteeArrToStr]
export const changeAnswerArrToStr = (answers: any[]) => {
  const result: string[] = []
  answers.forEach((item, index) => {
    result.push(arrToStrList[index](item))
  })
  return result
}
