import { SyntheticEvent, useContext, useEffect, useMemo } from 'react'

import contextData from '../contextData'

const BudgetHooks = (currentIndex: number) => {
  const { moduleList, dataCollect, mergeDataCollectFunction } = useContext(contextData)
  const currentData = moduleList[currentIndex]
  const minBudget = (useMemo(() => dataCollect?.[currentIndex]?.submitValue?.[0]?.value, [dataCollect]) as number) || 0
  const maxBudget = (useMemo(() => dataCollect?.[currentIndex]?.submitValue?.[1]?.value, [dataCollect]) as number) || 0
  const isCanNext = useMemo(() => maxBudget > 0, [maxBudget])
  const displayData = useMemo(() => `$${minBudget}-$${maxBudget}`, [minBudget, maxBudget])

  const sliderChange = (event: Event | SyntheticEvent, value: number | number[]) => {
    if (Array.isArray(value)) {
      const submitValue = [
        { name: dataCollect[currentIndex]?.submitValue?.[0].name, value: value?.[0] || 0 },
        { name: dataCollect[currentIndex]?.submitValue?.[1].name, value: value?.[1] || 0 },
      ]
      if (mergeDataCollectFunction) {
        mergeDataCollectFunction(currentIndex, { submitValue })
      }
    }
  }

  useEffect(() => {
    if (mergeDataCollectFunction) {
      mergeDataCollectFunction(currentIndex, {
        isCanNext,
        displayData,
      })
    }
  }, [isCanNext, displayData])

  return {
    currentData,
    maxBudget,
    minBudget,
    sliderChange,
  }
}

export default BudgetHooks
