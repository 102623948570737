import React, { FC } from 'react'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { Box, Typography } from '@mui/material'

import { PageTypeType } from './contantsAndType'

interface FooterProps {
  onClick: () => void
  onBack: () => void
  type: PageTypeType
  disabled: boolean
  nextLoading: boolean
  isReschedule?: boolean
}

const ScheduleTourFooter: FC<FooterProps> = props => {
  const { onClick, onBack, type, disabled, nextLoading, isReschedule } = props

  return (
    <Box className="fixed lg:relative   flex justify-between items-center w-full   bottom-0 py-4 px-5 bg-white ">
      {type === 'unit' && <div />}
      {type === 'unit' && (
        <Box
          onClick={() => {
            if (disabled) return
            onClick()
          }}
          className={`${
            disabled ? '!bg-[#F4F5FA] !text-[#A6A9BB]' : '!bg-[#E74F4F] !text-white'
          }  w-full lg:min-w-126px] text-center lg:max-w-[24.36%] cursor-pointer lg:!py-[9px] lg:!px-3 !rounded-xl !py-3 !px-10`}
        >
          <Typography className="!text-base !font-Averta6 font-semibold capitalize">Schedule Tour</Typography>
        </Box>
      )}
      {type !== 'unit' && (
        <>
          <Box
            onClick={onBack}
            className="w-[calc(50%-8px)] lg:min-w-126px] lg:max-w-[24.36%] cursor-pointer lg:!py-[9px] lg:!px-3 !rounded-xl !py-3 !px-10 !bg-white !text-[#050A22] border border-[#E0E2EF] text-center"
          >
            <Typography className="!text-base !font-Averta6 font-semibold  capitalize">{isReschedule ? 'Cancel' : 'Back'}</Typography>
          </Box>
          <LoadingButton
            onClick={() => {
              if (disabled) return
              onClick()
            }}
            loading={nextLoading}
            className={`${
              disabled || nextLoading ? '!bg-[#F4F5FA] !text-[#A6A9BB]' : '!bg-[#E74F4F] !text-white'
            } w-[calc(50%-8px)] lg:min-w-126px] lg:max-w-[24.36%] cursor-pointer lg:!py-[9px] lg:!px-3 !rounded-xl !py-3 !px-10 text-center`}
          >
            <Typography className="!text-base !font-Averta6 font-semibold  capitalize">
              {type === 'preference' && 'Next'}
              {type === 'submitTour' && isReschedule ? 'Reschedule' : ''}
              {type === 'submitTour' && !isReschedule ? 'Submit' : ''}
            </Typography>
          </LoadingButton>
        </>
      )}
    </Box>
  )
}

export default ScheduleTourFooter
