import baseHooks from './base'
import budgetHooks from './budget'
import creditScoreHooks from './creditScore'
import floorPlanHooks from './floorPlan'
import leaseTermHooks from './leaseTerm'
import moveInDateHooks from './moveInDate'
import parkingHooks from './parking'
import stayPlaningHooks from './stayPlaning'

export default {
  baseHooks,
  budgetHooks,
  floorPlanHooks,
  moveInDateHooks,
  creditScoreHooks,
  leaseTermHooks,
  stayPlaningHooks,
  parkingHooks,
}
