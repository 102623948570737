import React, { FC, useMemo } from 'react'
import { Alert, Box, LinearProgress, ThemeProvider } from '@mui/material'

import formHooks from './hooks/index'
import { theme } from './const'
import FormContextData from './contextData'
import FilledForm from './filledForm'
import Footer from './footer'
import Header from './header'
import ItemRender from './itemRender'
import PCModule from './PC'
import SubmitResult from './submitResult'
import { ModuleItemProps } from './types.d'

const { baseHooks } = formHooks

const FormVersionStable: FC = () => {
  const {
    id,
    sign,
    version,
    isPc,
    allStep,
    currentStep,
    setCurrentStep,
    isSubmit,
    canSubmit,
    isCanNext,
    moduleList,
    dataCollect,
    setDataCollect,
    onSubmit,
    showHeaderBack,
    loadingPreference,
    saveLoading,
    updateDataCollectSubmitValueFunction,
    mergeDataCollectFunction,
    isCoLiving,
    loadingCheck,
  } = baseHooks()

  const contextValues = useMemo(
    () => ({
      id,
      sign,
      version,
      isPc,
      allStep,
      currentStep,
      setCurrentStep,
      isSubmit,
      canSubmit,
      isCanNext,
      moduleList,
      dataCollect,
      setDataCollect,
      onSubmit,
      showHeaderBack,
      loadingPreference,
      saveLoading,
      updateDataCollectSubmitValueFunction,
      mergeDataCollectFunction,
      isCoLiving,
    }),
    [
      id,
      sign,
      version,
      isPc,
      allStep,
      currentStep,
      isSubmit,
      canSubmit,
      isCanNext,
      moduleList,
      dataCollect,
      showHeaderBack,
      loadingPreference,
      saveLoading,
      isCoLiving,
    ],
  )

  if (!id || !sign) {
    const message = !id ? 'id' : 'sign'
    return (
      <Alert severity="error" elevation={6} variant="filled">
        This is an incorrect link! {`(${message} missing)`}
      </Alert>
    )
  }
  if (loadingPreference || loadingCheck) {
    return (
      <ThemeProvider theme={theme}>
        <LinearProgress color="primary" />
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <FormContextData.Provider value={contextValues}>
        {isPc ? (
          <PCModule
            isCoLiving={isCoLiving}
            moduleList={moduleList}
            loading={saveLoading}
            disabled={!canSubmit}
            onSubmit={onSubmit}
            isSubmit={isSubmit}
            questionnaireId={id}
          />
        ) : (
          <>
            <Header
              onBack={() => {
                setCurrentStep(() => currentStep - 1)
              }}
              showBack={showHeaderBack}
            />
            <Box className="pt-12 pb-[95px]">
              {!isSubmit && (
                <>
                  <FilledForm isSubmit={isSubmit} dataCollect={dataCollect} setCurrentStep={setCurrentStep} currentStep={currentStep} />
                  {moduleList?.map((item: ModuleItemProps, index: number) =>
                    index === currentStep ? <ItemRender code={item?.code} index={index} key={index} /> : null,
                  )}
                </>
              )}
              {isSubmit && <SubmitResult isCoLiving={isCoLiving} questionnaireId={id} />}
            </Box>
            {!isSubmit && <Footer />}
          </>
        )}
      </FormContextData.Provider>
    </ThemeProvider>
  )
}

export default FormVersionStable
