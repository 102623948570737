import { useEffect, useMemo } from 'react'
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { Statsig } from 'statsig-react'

import { ScheduleTourTourType } from './contantsAndType'

// Helper function to apply custom styles to the shadow DOM
const applyCustomStyles = (shadowRoot: ShadowRoot) => {
  if (!shadowRoot.querySelector('style.custom-style')) {
    const style = document.createElement('style')
    style.classList.add('custom-style')
    style.textContent = `
      .atcb-initialized {
        width: calc(100% - 32px);
      }
      #atcb-reference {
        display: none;
      }
      .atcb-list-item {
        width: 100%;
        background: #fff;
        padding: 12px 10px;
      }
      .atcb-icon-trigger {
        display: none;
      }
      .atcb-button {
        background-color: #E74F4F;
        width: 100%;
        box-shadow: none;
        border-radius: 12px;
        max-width: 100%;
        color: #fff;
        border: 0;
        cursor: pointer;
        margin: 0;
        padding: 12px 16px;
      }
      .atcb-button:hover,
      .atcb-button.atcb-active:not(.atcb-modal-style,.atcb-dropoverlay),
      .atcb-button.atcb-single:not([disabled]):focus,
      .atcb-button.atcb-single:not([disabled]):hover {
        background-color: #E74F4F !important;
        box-shadow: none !important;
        color: #fff !important;
        padding: 12px 16px !important;
      }
      .atcb-checkmark {
        display: none !important;
      }
    `
    shadowRoot.appendChild(style)
  }
}

// Helper function to add the SVG icon to the button
const addSvgIconToButton = (shadowRoot: ShadowRoot) => {
  const button = shadowRoot.querySelector('.atcb-button')
  if (button && !button.querySelector('svg.custom-svg-icon')) {
    // Check if there's already an svg with the 'custom-svg-icon' class
    const svgIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    svgIcon.setAttribute('width', '24')
    svgIcon.setAttribute('height', '24')
    svgIcon.setAttribute('viewBox', '0 0 24 24')
    svgIcon.setAttribute('fill', 'none')
    svgIcon.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
    svgIcon.style.marginRight = '4px'

    // Add a class to the SVG icon
    svgIcon.classList.add('custom-svg-icon') // Add the class here

    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    path.setAttribute(
      'd',
      'M9 11H7V13H9V11ZM13 11H11V13H13V11ZM17 11H15V13H17V11ZM19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V9H19V20Z',
    )
    path.setAttribute('fill', 'white')
    svgIcon.appendChild(path)
    button.prepend(svgIcon)

    button.addEventListener('click', () => {
      Statsig.logEvent('survey_link_to_calendar', window.location.href)
    })
  }
}

export default function CustomAddToCalendarButton({ data }: { data: ScheduleTourTourType & { prospectUserName: string } }) {
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const atcbElement = document.querySelector('add-to-calendar-button')
      if (atcbElement) {
        const { shadowRoot } = atcbElement
        if (shadowRoot) {
          applyCustomStyles(shadowRoot)
          addSvgIconToButton(shadowRoot)
        }
      }
    })

    observer.observe(document.body, { childList: true, subtree: true })

    return () => observer.disconnect()
  }, [])

  const tourUnitStr = useMemo(() => {
    if (!data) return ''
    return data.units
      .map(item => {
        if (item.unitName && item.unitId) {
          return `${item.floorPlanName} - ${item.unitName}`
        }
        return `${item.floorPlanName} - Other Unit`
      })
      .join('; ')
  }, [data])

  const formattedStartTime = useMemo(
    () =>
      data.startTime
        ? `${momentTimezone(data.startTime).tz(data.timeZone).format('h:mm a, dddd, MMM DD, YYYY')} ${data.timeZoneText}`
        : '-',
    [data.startTime, data.timeZone, data.timeZoneText],
  )

  return (
    <AddToCalendarButton
      trigger="click"
      name={`${data.prospectUserName} tour at ${data.propertyName}`}
      startDate={moment(data.startTime).format('YYYY-MM-DD')}
      startTime={moment(data.startTime).format('HH:mm')}
      endTime={moment(data.startTime).add(30, 'minutes').format('HH:mm')}
      timeZone={data.timeZone}
      location={data.propertyAddress}
      lightMode="bodyScheme"
      description={`
        Dear ${data.prospectUserName}, Thank you for scheduling a tour with Tripalink![br]
        • Date and time: ${formattedStartTime}[br]
        • Meet at: ${data.propertyAddress}[br]
        • Homes to tour:[br]
        - ${data?.propertyName}: ${tourUnitStr}[br]
        ${data.propertyAddress}[br]
        Our team member will meet you at the property. Please arrive on time and feel free to contact us if you have any questions.[br]
        We value your feedback. Please take a moment to share your tour scheduling experience: [url]https://qn.tripalink.com/r/2hWI15|Click here![/url][br]
        We look forward to meeting you.
      `}
      options={['Apple', 'Google', 'iCal', 'Outlook.com']}
      label="Add to calendar"
      hideBackground
      listStyle="overlay"
    />
  )
}
