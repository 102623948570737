import React, { FC, useEffect } from 'react'
import { Typography } from '@mui/material'
import { animated, useSpring } from '@react-spring/web'
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react'

import CloseIcon from '../../icons/close'

interface CheckImageProps {
  src: string
  alt: string
  visible: boolean
  onClose: () => void
}
const useGesture = createUseGesture([dragAction, pinchAction])

const CheckImage: FC<CheckImageProps> = props => {
  const { src, alt, visible, onClose } = props
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.removeProperty('overflow')
    }
  }, [visible])

  const [style, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
    rotateZ: 0,
  }))
  const ref = React.useRef<HTMLDivElement>(null)

  useGesture(
    {
      onDrag: ({ pinching, cancel, offset: [x, y] }) => {
        if (pinching) return cancel()
        api.start({ x, y })
        return undefined
      },
      onPinch: ({ origin: [ox, oy], first, movement: [ms], offset: [s, a], memo }) => {
        if (!ref.current) return
        if (first) {
          const { width, height, x, y } = ref.current.getBoundingClientRect()
          const tx = ox - (x + width / 2)
          const ty = oy - (y + height / 2)
          memo = [style.x.get(), style.y.get(), tx, ty]
        }

        const x = memo[0] - (ms - 1) * memo[2]
        const y = memo[1] - (ms - 1) * memo[3]
        api.start({ scale: s, rotateZ: a, x, y })
        // eslint-disable-next-line consistent-return
        return memo
      },
    },
    {
      target: ref,
      drag: { from: () => [style.x.get(), style.y.get()] },
      pinch: { scaleBounds: { min: 0.5, max: 2 }, rubberband: true },
    },
  )

  useEffect(() => {
    const handler = (e: Event) => e.preventDefault()
    document.addEventListener('gesturestart', handler)
    document.addEventListener('gesturechange', handler)
    document.addEventListener('gestureend', handler)
    return () => {
      document.removeEventListener('gesturestart', handler)
      document.removeEventListener('gesturechange', handler)
      document.removeEventListener('gestureend', handler)
    }
  }, [])

  if (!visible) return null
  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 w-screen h-screen overflow-scroll bg-white z-30">
      <div className="flex justify-end items-center px-4 py-3">
        <Typography className="!font-averta6 !text-base !text-[#050A22] !font-semibold">Close</Typography>
        &nbsp;
        <CloseIcon onClick={onClose} fontSize="inherit" className="cursor-pointer" />
      </div>

      <animated.div ref={ref} className="px-4 overflow-scroll" style={{ touchAction: 'none', ...style }}>
        <img src={src} alt={alt} className="w-full" />
      </animated.div>
    </div>
  )
}
export default CheckImage
