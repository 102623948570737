import React, { FC } from 'react'
import { Box, Divider, List, ListItem } from '@mui/material'

import { ModuleListItemType } from '../../../types/form.d'

interface FilledFormProps {
  moduleList: Omit<ModuleListItemType, 'data'>[]
  onBackToStep: (step: number) => void
  currentStep: number
}
const FilledForm: FC<FilledFormProps> = props => {
  const { moduleList, onBackToStep, currentStep } = props
  if (currentStep === 1) return null
  return (
    <List className="!py-3 !px-[23px] w-full font-Averta">
      <div className="bg-[#F7F9FC] rounded-lg">
        {moduleList.map((item, index) => {
          if (index >= currentStep - 1) return null
          return (
            <Box key={item.key}>
              <ListItem
                onClick={() => {
                  onBackToStep(index + 1)
                }}
                className="!p-4 flex items-center !justify-between"
              >
                <span className="text-sm text-[#050A22] font-Averta4">{item.label}</span>
                <span className="text-sm text-[#050A22] font-Averta6">{item.displayData}</span>
              </ListItem>
              {index < currentStep - 2 && <Divider flexItem variant="middle" sx={{ borderColor: '#E0E2EF' }} component="li" />}
            </Box>
          )
        })}
      </div>
    </List>
  )
}
export default FilledForm
