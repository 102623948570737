import React, { FC } from 'react'
import { Box } from '@mui/material'

import Logo from '../../../assets/imgs/rentyBlackIcon.svg'
import Back from '../../../icons/back'

interface FormV4HeaderProps {
  onBack: () => void
  showBack: boolean
}

const HOME_URL = 'https://www.tripalink.com'
const IMG_URL = `${process.env.REACT_APP_IMG_URL}2002e05943b43ee0.png`

// only mobile
const FormV4Header: FC<FormV4HeaderProps> = props => {
  const { onBack, showBack } = props

  return (
    <Box className="fixed top-0 w-full bg-white z-10">
      <Box className="flex items-center p-3">
        <Box className="w-1/4">
          {showBack && (
            <Box onClick={onBack} className="flex items-center justify-start">
              <Back sx={{ width: '24px!important', height: '24px!important' }} />
            </Box>
          )}
        </Box>
        <Box className="flex-grow">
          <a href={HOME_URL} className="block m-auto w-36 h-6">
            <img src={Logo} alt="tripalink" />
          </a>
        </Box>
        <Box className="w-1/4" />
      </Box>
    </Box>
  )
}

export default FormV4Header
