import React from 'react'
import { Box, Checkbox, Chip, Typography } from '@mui/material'

import indexHooks from './hooks/index'
import { CommonModuleProps, MODULES_CODE_ENUM, QuestionItemProps } from './types.d'

const { creditScoreHooks } = indexHooks
const guaranteeList = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
]

const CreditScore = ({ currentIndex }: CommonModuleProps) => {
  const {
    scoreList,
    showChildrenKey,
    currentData,
    creditScoreType,
    isStudent,
    cosigner,
    onChangeScore,
    onChangeIsStudent,
    onChangeGuarantee,
    isPc = false,
  } = creditScoreHooks(currentIndex)

  return (
    <>
      <Box className="w-full !px-5 !py-4">
        <Typography className="!font-Averta6 !text-lg">
          {currentIndex + 1}. {currentData?.title}
        </Typography>
      </Box>
      <Box className="w-full !px-5 !py-2">
        {isPc ? (
          <div className="w-full grid gap-y-4">
            {scoreList.map((score: QuestionItemProps) => (
              <Box
                onClick={() => onChangeScore(score.key)}
                className={`${
                  score.key === creditScoreType ? '!border-[#050A22]' : '!border-[#E0E2EF]'
                } flex items-center cursor-pointer justify-start border rounded-xl p-4`}
                key={score.key}
              >
                <Box
                  className={`w-4 h-4 border rounded-2xl mr-4 ${
                    score.key === creditScoreType ? 'border-[#E74F4F] border-[6px]' : 'border-[#E0E2EF] '
                  }`}
                />
                <Typography className="!font-Averta4 !text-sm !leading-6">{score.desc}</Typography>
              </Box>
            ))}
          </div>
        ) : (
          <div className="w-full grid gap-y-3">
            {scoreList.map((score: QuestionItemProps) => (
              <Chip
                onClick={() => onChangeScore(score.key)}
                className={`${score.key === creditScoreType ? '!border-[#050A22]' : '!border-[#E0E2EF]'} !font-Averta4 !text-sm !h-9`}
                label={<span className="font-Averta4 text-sm leading-6">{score.desc}</span>}
                key={score.key}
                variant="outlined"
              />
            ))}
          </div>
        )}
      </Box>
      {showChildrenKey?.includes(MODULES_CODE_ENUM?.IS_STUDENT) && (
        <Box className="flex items-center !px-5">
          <Checkbox
            sx={{
              color: '#E0E2EF',
              padding: '0',
              '&.Mui-checked': {
                color: '#050A22',
              },
              '& svg': {
                fontSize: '24px',
              },
            }}
            defaultChecked={isStudent}
            onChange={() => {
              onChangeIsStudent(!isStudent)
            }}
          />
          &nbsp;&nbsp;
          <span className="font-Averta4 text-sm">{currentData?.question?.isStudent?.desc}</span>
        </Box>
      )}
      {showChildrenKey?.includes(MODULES_CODE_ENUM?.COSIGNER) && !isStudent && (
        <>
          <Box className="w-full !px-5 !py-4">
            <Typography className="!font-Averta6 !text-lg">{currentData?.question?.cosigner?.title}</Typography>
          </Box>
          <Box className="w-full !px-5 !py-2">
            <div className="flex items-center justify-between">
              {isPc
                ? guaranteeList.map((g, ind) => (
                    <Box
                      onClick={() => {
                        onChangeGuarantee(g.value)
                      }}
                      className={`${
                        cosigner === g.value ? '!border-[#050A22]' : '!border-[#E0E2EF]'
                      } flex items-center cursor-pointer justify-start  p-4 !font-Averta4 w-[45%] !text-sm border rounded-2xl`}
                      key={ind}
                    >
                      <Box
                        className={`w-4 h-4 border rounded-2xl mr-4 ${
                          g.value === cosigner ? 'border-[#E74F4F] border-[6px]' : 'border-[#E0E2EF] '
                        }`}
                      />
                      <Typography className="!font-Averta4 !text-sm !leading-6">{g.label}</Typography>
                    </Box>
                  ))
                : guaranteeList.map((g, index) => (
                    <Chip
                      onClick={() => {
                        onChangeGuarantee(g.value)
                      }}
                      className={`${
                        cosigner === g.value ? '!border-[#050A22]' : '!border-[#E0E2EF]'
                      } !font-Averta4 w-[45%] !text-sm border rounded-[50px]`}
                      label={<span className="font-Averta4 text-sm leading-6">{g.label}</span>}
                      key={index}
                      variant="outlined"
                    />
                  ))}
            </div>
          </Box>
        </>
      )}
    </>
  )
}

export default CreditScore
