import React, { FC } from 'react'
import { Box, Divider, List, ListItem } from '@mui/material'

import { DataCollectProps } from './types.d'

interface FilledFormProps {
  dataCollect: DataCollectProps[]
  setCurrentStep: (step: number) => void
  currentStep: number
  isSubmit: boolean
}
const FilledForm: FC<FilledFormProps> = props => {
  const { dataCollect, setCurrentStep, currentStep, isSubmit } = props
  if (currentStep === 0 || isSubmit) return null
  return (
    <List className="!py-3 !px-[23px] w-full font-Averta">
      <div className="bg-[#F7F9FC] rounded-lg">
        {dataCollect?.map((item, index) => {
          if (index >= currentStep) return null
          return (
            <Box key={item.code}>
              <ListItem
                onClick={() => {
                  setCurrentStep(index)
                }}
                className="!p-4 flex items-center !justify-between"
              >
                <span className="text-sm text-[#050A22] font-Averta4">{item.label}</span>
                <span className="text-sm text-[#050A22] font-Averta6 text-right">{item.displayData}</span>
              </ListItem>
              <Divider flexItem variant="middle" sx={{ borderColor: '#E0E2EF' }} component="li" />
            </Box>
          )
        })}
      </div>
    </List>
  )
}
export default FilledForm
