import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useMediaQuery } from '@mui/material'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { Statsig } from 'statsig-react'

import { SAVE_QUESTIONNAIRE_V2, SAVE_URL_TTL_LOG } from '../../../../graphqls/mutations'
import { QUERY_PREFERENCE_BY_QUESTIONNAIRE_ID, QUESTIONNAIRE_CHECK_V2 } from '../../../../graphqls/queries'
import formUtils from '../../../../utils/form'
import { DataCollectProps, ModuleItemProps, MODULES_CODE_ENUM, QuestionItemProps } from '../types.d'

const { initDataFunction } = formUtils

let logInterval: NodeJS.Timer | null = null

const BaseHooks = () => {
  const [searchParams] = useSearchParams({ id: '', sign: '' })
  const id = searchParams.get('id')
  const sign = searchParams.get('sign')
  const version = searchParams.get('version')
  const channel = searchParams.get('channel')
  const organizationId = searchParams.get('organizationId')
  const isPc = useMediaQuery('(min-width:1024px)')
  const [dataCollect, setDataCollect] = useState<DataCollectProps[]>([])
  const [currentStep, setCurrentStep] = useState<number>(-1)
  const [moduleList, setModuleList] = useState<ModuleItemProps[]>([])
  const [isCoLiving, setIsColiving] = useState<boolean>(false)

  const allStep = useMemo(() => dataCollect?.length, [dataCollect])

  // mobile can next
  const isCanNext = useMemo(() => {
    const currentModuleIndex = dataCollect?.findIndex((item, index) => index === currentStep && item?.isCanNext)
    return currentModuleIndex > -1
  }, [dataCollect, currentStep])
  // mobile is submit
  const isSubmit = useMemo(() => currentStep >= allStep, [allStep, currentStep])

  // can submit
  const canSubmit = useMemo(() => dataCollect?.findIndex(item => !item?.isCanNext) === -1, [dataCollect])

  // show header back
  const showHeaderBack = useMemo(() => {
    if (isSubmit) return false
    return currentStep > 0 && currentStep < allStep
  }, [currentStep, allStep, isSubmit])

  const updateDataCollectSubmitValueFunction = (index: number, submitValueIndex: number, value: any) => {
    const newList = cloneDeep(dataCollect)
    newList[index].submitValue[submitValueIndex].value = value
    setDataCollect(newList)
  }

  const mergeDataCollectFunction = (index: number, data: any) => {
    if (dataCollect?.length <= index) return
    const newList = cloneDeep(dataCollect)
    newList[index] = {
      ...newList[index],
      ...data,
    }
    setDataCollect(JSON.parse(JSON.stringify(newList)))
  }

  const [saveUrlTtlLogApi] = useMutation(SAVE_URL_TTL_LOG, {
    variables: { input: { questionnaireId: id, source: 'QUESTIONNAIRE' } },
  })

  const getCreditScoreDisplayData = (creditScoreType: string, isStudent: boolean, cosigner: string) => {
    const creditScoreData = moduleList?.find(item => item?.code === MODULES_CODE_ENUM.CREDIT_SCORE)
    const showChildrenKey: string[] =
      creditScoreData?.question?.creditScore?.find((item: QuestionItemProps) => item?.key === creditScoreType)?.childrenKey || []
    const creditScoreTypeText =
      creditScoreData?.question?.creditScore?.find((item: QuestionItemProps) => item?.key === creditScoreType)?.desc || ''
    const isStudentText = isStudent ? 'I am a student' : ''
    const cosignerText =
      cosigner !== null && showChildrenKey?.includes(MODULES_CODE_ENUM.COSIGNER) ? (cosigner ? 'With guarantor' : 'No guarantor') : ''
    return `${creditScoreTypeText}${isStudentText ? `/${isStudentText}` : ''}${cosignerText ? `/${cosignerText}` : ''}`
  }

  const getFloorPlanDisplayData = (bedRoomQuantity: string[], bathRoomQuantity: string[]) => {
    const floorPlanData = moduleList?.find(item => item?.code === MODULES_CODE_ENUM.FLOOR_PLAN)
    const bedroomList = floorPlanData?.question?.bedroom
    const bathroomList = floorPlanData?.question?.bathroom
    let str = ''
    const tempBedroomList = bedroomList?.filter((item: { key: string }) => bedRoomQuantity?.includes(item.key)) || []
    const tempBathroomList = bathroomList?.filter((item: { key: string }) => bathRoomQuantity?.includes(item.key)) || []
    const displayBedSuffix = tempBedroomList?.length > 1 || (tempBedroomList?.length === 1 && tempBedroomList[0]?.key !== '0')
    const displayBedSemicolon = tempBathroomList?.length > 0
    str = `${tempBedroomList.map((i: { value: string }) => i.value).join(', ')}${displayBedSuffix ? ' bed(s)' : ''}${
      displayBedSemicolon ? ';' : ''
    }`
    if (tempBathroomList.length > 0) {
      str += `${tempBathroomList.map((i: { value: string }) => i.value).join(',')} bath;`
    }

    return str
  }

  const { loading: loadingCheck } = useQuery(QUESTIONNAIRE_CHECK_V2, {
    variables: {
      request: { id, sign, version: Number(version), organizationId },
    },
    onCompleted: res => {
      setModuleList(JSON.parse(res?.questionnaireCheckV2?.questionList || '[]'))
      setIsColiving(res?.questionnaireCheckV2?.isCoLiving)
      fetchPreference()
    },
  })

  const [fetchPreference, { loading: loadingPreference }] = useLazyQuery(QUERY_PREFERENCE_BY_QUESTIONNAIRE_ID, {
    variables: {
      questionnaireId: id,
    },
    onCompleted(data) {
      const { queryPreferenceByQuestionnaireId } = data || {}
      const {
        leaseTerm: defaultLeaseTerm,
        moveInDateBegin,
        parking,
        isStudent,
        creditScoreType,
        cosigner,
        budgetMin,
        budgetMax,
        bedRoomQuantity,
        bathRoomQuantity,
        studentStayPlan,
      } = queryPreferenceByQuestionnaireId || {}
      const moveInDateValue = moveInDateBegin ? moment(moveInDateBegin)?.format('YYYY-MM-DD') : ''
      const moveInDateDisplay = moveInDateBegin ? moment(moveInDateBegin)?.format('MM/DD/YYYY') : ''
      const dataCollectList: DataCollectProps[] = []
      const fillDataMap: {
        [key: string]: any
      } = {
        floorPlan: {
          selectBedroom: bedRoomQuantity,
          selectBathroom: bathRoomQuantity,
          isCanNext: bedRoomQuantity?.length > 0,
          displayData: getFloorPlanDisplayData(bedRoomQuantity, bathRoomQuantity),
        },
        budget: {
          minBudget: budgetMin || 0,
          maxBudget: budgetMax || 0,
          isCanNext: budgetMax > 0,
          displayData: `$${budgetMin}-$${budgetMax}`,
        },
        moveInDate: {
          moveInDate: {
            from: moveInDateValue,
            to: moveInDateValue,
          },
          displayData: moveInDateDisplay,
          isCanNext: Boolean(moveInDateBegin),
        },

        creditScore: {
          creditScoreType,
          isStudent,
          cosigner: cosigner !== null ? cosigner : null,
          displayData: getCreditScoreDisplayData(creditScoreType, isStudent, cosigner),
          isCanNext: Boolean(creditScoreType),
        },
        stayPlaning: {
          stayPlanType: studentStayPlan,
          leaseTerm: studentStayPlan === MODULES_CODE_ENUM.CUSTOM_MONTHS ? defaultLeaseTerm : '0',
          displayData: `${studentStayPlan || ''}${defaultLeaseTerm > 0 ? `-${defaultLeaseTerm} months` : ''}`,
          isCanNext: studentStayPlan === MODULES_CODE_ENUM.CUSTOM_MONTHS ? defaultLeaseTerm > 0 : Boolean(studentStayPlan),
        },
        leaseTerm: {
          leaseTerm: defaultLeaseTerm,
          displayData: `${defaultLeaseTerm} months`,
          isCanNext: defaultLeaseTerm > 0,
        },
        parkingSpot: {
          parking: parking?.toString(),
          displayData: `${parking} parking`,
          isCanNext: parking !== null && (Number(parking) === 0 || Boolean(parking)),
        },
      }
      moduleList?.forEach((item: ModuleItemProps) => {
        if (item?.code) {
          const values = fillDataMap[item?.code]
          dataCollectList.push(
            initDataFunction[item?.code]({
              ...item,
              ...values,
            }),
          )
        }
      })
      setDataCollect(dataCollectList)
      const emptyIndex = dataCollectList.findIndex(item => !item?.isCanNext)
      setCurrentStep(emptyIndex > -1 ? emptyIndex : moduleList?.length)
    },
    onError() {
      Statsig.logEvent('survey_v4_fetch_preference_error', window.location.href, {
        questionnaireId: id,
      })
    },
  })

  const [saveQuestion, { loading: saveLoading }] = useMutation(SAVE_QUESTIONNAIRE_V2, {
    onCompleted: res => {
      if (res?.saveQuestionnaireV2?.saveResult) {
        setCurrentStep(allStep)
        if (logInterval) {
          clearInterval(logInterval)
        }
      }
    },
    onError: () => {
      setCurrentStep(allStep)
    },
  })
  const onSubmit = ({ submit }: { submit: boolean }) => {
    const values: { [key: string]: string | string[] } = {}

    dataCollect?.forEach(dataItems => {
      dataItems?.submitValue?.forEach((item: { name: string; value: string | number | boolean | string[] }) => {
        if (!item.name) return
        if (typeof item.value === 'string' || typeof item.value === 'number' || typeof item.value === 'boolean') {
          values[item.name] = String(item.value)
        }
        if (item.value instanceof Array) {
          values[item.name] = item.value
        }
      })
    })
    Statsig.logEvent('survey_v4_next_or_submit', window.location.href, {
      ...(values as unknown as Record<string, string>),
      isSubmit: isSubmit.toString(),
      isPCUI: isPc.toString(),
      step: (currentStep + 1).toString(),
    })
    if (!submit) {
      setCurrentStep(currentStep + 1)
      return
    }
    saveQuestion({
      variables: {
        request: {
          id,
          sign,
          version: Number(version),
          channel,
          organizationId,
          ...values,
        },
      },
    })
  }

  const checkLogInterval = () => {
    if (document.visibilityState === 'hidden' && logInterval) {
      clearInterval(logInterval)
    }
    if (document.visibilityState === 'visible') {
      saveUrlTtlLogApi()
      if (logInterval) clearInterval(logInterval)
      logInterval = setInterval(saveUrlTtlLogApi, 10000)
    }
  }

  useEffect(() => {
    if (id && sign) {
      Statsig.logEvent('survey_v4_PV_UV', window.location.href, { id, sign })
    }
    checkLogInterval()
    document.addEventListener('visibilitychange', checkLogInterval)
    return () => {
      if (logInterval) clearInterval(logInterval)
      document.removeEventListener('visibilitychange', checkLogInterval)
    }
  }, [])

  useEffect(() => {
    if (!isPc) Statsig.logEvent('survey_v4_mobile_page', window.location.href, { id, sign, currentStep: (currentStep - 1).toString() })
  }, [currentStep])

  return {
    id,
    sign,
    version,
    channel,
    organizationId,
    isPc,
    allStep,
    currentStep,
    setCurrentStep,
    isSubmit,
    canSubmit,
    isCanNext,
    moduleList,
    dataCollect,
    setDataCollect,
    onSubmit,
    showHeaderBack,
    loadingPreference,
    saveLoading,
    updateDataCollectSubmitValueFunction,
    mergeDataCollectFunction,
    isCoLiving,
    loadingCheck,
  }
}

export default BaseHooks
