import React, { FC, useContext, useLayoutEffect } from 'react'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { Box, Typography } from '@mui/material'

import CheckIcon from '../../../../assets/imgs/checkIcon.svg'
import Logo from '../../../../assets/imgs/surveyLogo.svg'
import contextData from '../contextData'
import ItemRender from '../itemRender'
import SubmitResult from '../submitResult'
import { ModuleItemProps } from '../types.d'

interface PCprops {
  moduleList: ModuleItemProps[]
  disabled: boolean
  loading: boolean
  onSubmit: ({ submit }: { submit: boolean }) => void
  isSubmit: boolean
  questionnaireId: string
  isCoLiving: boolean
}

const PC: FC<PCprops> = props => {
  const { moduleList, disabled, loading, onSubmit, isSubmit, questionnaireId, isCoLiving } = props
  const { allStep } = useContext(contextData)
  useLayoutEffect(() => {
    document.body.style.backgroundColor = '#f7f9fc'
  }, [])
  return (
    <div>
      <div className="bg-[#111212] sticky z-20 top-0  px-10 h-16 flex items-center justify-start">
        <img src={Logo} className="h-6" alt="tripalink" />
        <div className="bg-[white] mx-8 h-5 w-[1px] border-box" />
        <Typography className="!font-Averta6 !text-lg !text-[white]">Rental Preference Survey</Typography>
      </div>
      <div className="min-h-[calc(100vh-64px)] w-1/2 m-auto bg-white px-[100px] pt-12 pb-10">
        {isSubmit && !loading ? (
          <Box className="pt-[200px]">
            <SubmitResult isCoLiving={isCoLiving} questionnaireId={questionnaireId} isPC />
          </Box>
        ) : (
          <>
            <div className="p-4 bg-[#F7F9FC] rounded flex  items-start">
              <img src={CheckIcon} className="w-4  mr-2 mt-0.5" alt="" />
              <span className="font-[400] text-sm">
                Please answer the following {allStep} questions so we can find the right home for your needs. All questions are required.
              </span>
            </div>

            {moduleList?.map((item: ModuleItemProps, index: number) => (
              <ItemRender code={item?.code} index={index} key={index} />
            ))}

            <LoadingButton
              className={`${
                disabled || loading ? 'bg-gray-200 cursor-not-allowed' : 'bg-red-450 cursor-pointer'
              }  rounded-md px-4 py-1 ml-2 mt-2`}
              onClick={() =>
                onSubmit({
                  submit: true,
                })
              }
              loading={loading}
              disabled={disabled || loading}
            >
              <Typography className="capitalize !font-Averta6 !text-base text-white">Submit</Typography>
            </LoadingButton>
          </>
        )}
      </div>
    </div>
  )
}

export default PC
