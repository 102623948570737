import React from 'react'
import { RangePicker as ReactRangePicker } from 'react-trip-date'
import { Box, Typography } from '@mui/material'
import moment from 'moment'

import indexHooks from './hooks/index'
import { CommonModuleProps } from './types.d'

const { moveInDateHooks } = indexHooks

const MoveDate = ({ currentIndex }: CommonModuleProps) => {
  const { moveInDate, currentData, onChange } = moveInDateHooks(currentIndex)

  return (
    <>
      <Box className="w-full !px-5 !py-4">
        <Typography className="!font-Averta6 !text-lg">
          {currentIndex + 1}. {currentData?.title}
        </Typography>
        <Typography className="!font-Averta4 !text-sm !mt-2 text-[#75798D]">{currentData?.desc}</Typography>
      </Box>
      <Box className="w-full !px-5 !py-4 min-h-[378px]">
        <ReactRangePicker
          components={{
            header: {
              format: `MMMM YYYY`,
            },
          }}
          numberOfMonths={1}
          autoResponsive={false}
          initialMonthAndYear={moveInDate.from || moment()?.format('YYYY-MM-DD')}
          onChange={onChange}
          disabledBeforeToday
          selectedDays={{ from: moveInDate.from, to: moveInDate.from }}
        />
      </Box>
    </>
  )
}

export default MoveDate
