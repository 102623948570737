import React from 'react'
import { Box, Typography } from '@mui/material'

import indexHooks from './hooks/index'
import { CommonModuleProps, QuestionItemProps } from './types.d'

const { parkingHooks } = indexHooks

const Parking = ({ currentIndex }: CommonModuleProps) => {
  const { parkingSpot, onParkingChange, currentData } = parkingHooks(currentIndex)

  return (
    <div>
      <Box className="w-full !px-5 !py-4">
        <Typography className="!font-Averta6 !text-lg">
          {currentIndex + 1}. {currentData?.title}
        </Typography>
      </Box>
      <Box className="w-full !px-5 !py-4">
        <div className="w-full grid gap-y-4">
          {currentData?.question?.parkingSpot?.map((item: QuestionItemProps) => {
            const active = item.key === parkingSpot
            return (
              <Box
                onClick={() => onParkingChange(item.key)}
                className={`${
                  active ? '!border-[#050A22]' : '!border-[#E0E2EF]'
                } flex items-center cursor-pointer justify-start border rounded-xl px-4 py-2.5 `}
                key={item.key}
              >
                <Box className={`w-4 h-4 border rounded-2xl mr-4 ${active ? 'border-[#E74F4F] border-[6px]' : 'border-[#E0E2EF] '}`} />
                <Typography className="!font-Averta4 !text-sm !leading-6">{item.desc}</Typography>
              </Box>
            )
          })}
        </div>
      </Box>
    </div>
  )
}

export default Parking
