import { Box, Slider, Typography } from '@mui/material'

import { generateRentOptions } from '../helpers'
import { RentBudgetField } from '../RentBudgetField'
import hooksIndex from './hooks/index'
import { CommonModuleProps } from './types.d'

const { budgetHooks } = hooksIndex

const Budget = ({ currentIndex }: CommonModuleProps) => {
  const { currentData, minBudget, maxBudget, sliderChange } = budgetHooks(currentIndex)

  const minRentOptions = generateRentOptions(0, 12000, 100)
  const maxRentOptions = generateRentOptions(minBudget, 12000, 100)

  return (
    <>
      <Box className="w-full !px-5 !py-4">
        <Typography className="!font-Averta6 !text-lg">
          {currentIndex + 1}. {currentData?.title}
        </Typography>
      </Box>
      <Box className="w-full !px-5 !py-4">
        <div className="flex justify-center items-center">
          <RentBudgetField
            label="Min"
            options={minRentOptions}
            value={minBudget}
            onChange={(e, newMinBudget) => {
              const newMaxBudget = newMinBudget > maxBudget ? newMinBudget : maxBudget
              sliderChange(e, [newMinBudget, newMaxBudget])
            }}
          />
          <span className="flex-grow text-[#75798D] font-Averta4 text-base text-center">To</span>
          <RentBudgetField
            label="Max"
            options={maxRentOptions}
            value={maxBudget}
            onChange={(e, newMaxBudget) => {
              const newMinBudget = newMaxBudget < minBudget ? newMaxBudget : minBudget
              sliderChange(e, [newMinBudget, newMaxBudget])
            }}
          />
        </div>
        <div className="mt-6">
          <Slider
            onChange={sliderChange}
            step={100}
            value={[minBudget, maxBudget]}
            max={currentData?.question?.maxBudget || 0}
            min={currentData?.question?.minBudget || 0}
            color="secondary"
          />
        </div>
      </Box>
    </>
  )
}

export default Budget
