import React from 'react'
import { Box, Stack, Typography } from '@mui/material'

import CalendarFilled from '../../icons/calendarFilled'
import { getCurrency } from '../../utils/index'
import { ScheduleTourPropertyType } from './contantsAndType'

const PropertyCard = (props: { property: ScheduleTourPropertyType; onClick: () => void }) => {
  const { property, onClick } = props

  return (
    <Box style={{ boxShadow: '0px 0px 3px 0px #242A2E1A' }} className="rounded-xl overflow-hidden" onClick={onClick}>
      <img src={property.headPicUrl} alt="tripalink" className="w-auto" />
      <div className="p-3 flex items-start flex-col ">
        {property.minPrice && (
          <div className="mb-2 flex flex-row items-baseline justify-start">
            <Typography className="!font-Averta6 !font-bold !text-[#050A22] !text-[22px] ">{`$${getCurrency(
              property.minPrice,
            )}+/mo`}</Typography>
            &nbsp;&nbsp;
            {property.minOriginalPrice && Number(property.minOriginalPrice) > Number(property.minPrice) && (
              <Typography className="!font-Averta4 line-through  !text-[#75798D] !text-sm ">
                {`$${getCurrency(property.minOriginalPrice)}`}
              </Typography>
            )}
          </div>
        )}
        <Typography className="!font-Averta6 !text-[#050A22] !text-base !mb-2">{property.aliasName || property.name}</Typography>

        <Box className="w-full overflow-x-scroll">
          {property?.amenities?.length > 0 && (
            <Stack direction="row" spacing={1}>
              {property?.amenities?.map(amenity => (
                <span
                  key={amenity?.catalogName}
                  className="bg-[#F7F9FC] rounded-lg px-2 py-1 text-[#75798D] font-Averta4 text-sm whitespace-nowrap"
                >
                  {amenity?.catalogName}
                </span>
              ))}
            </Stack>
          )}
        </Box>
      </div>
    </Box>
  )
}

export default PropertyCard
