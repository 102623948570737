import React, { useMemo } from 'react'

import ErrorSnackbar from '../errorSnackbar'
import { getTourTypeName } from './contantsAndType'
import ScheduleTourPreference from './scheduleTourPreference'
import SchedultTourWayAndTime from './scheduleTourWayAndTime'
import SelectedForm from './selectedForm'
import { fillOnlySelectFloorplanSelectedUnits } from './utils'

export default function SubmitTourPc({
  selfGuidTourUnits,
  prospectId,
  property,
  preference: selectedPreferences,
  onChangeTime: setSelectedAgentTime,
  onBackNewUserId: setNewAdminUserId,
  onChangeType: setTourType,
  agentInfo,
  selectedUnits,
  selectedProperty,
  timeZoneInfo: selectedPropertyTimeZone,
  type: tourType,
  typeList: tourTypeList,
  setSelectedPreferences,
  havePerference,
  isReschedule,
  setAddTourError,
  addTourError,
  isCentralizedStage,
  onNoAvailableDay,
}: any) {
  return (
    <div className="min-h-[calc(100vh-75px)] pt-5 pb-[95px] px-5">
      <SelectedForm
        selectedUnits={selectedUnits}
        filledSelectedUnits={fillOnlySelectFloorplanSelectedUnits(selectedUnits, selectedProperty)}
        property={property}
        preference={selectedPreferences}
        tourOption={isReschedule ? getTourTypeName(tourType) : ''}
      />

      {havePerference && !isReschedule && (
        <ScheduleTourPreference selectedPreferences={selectedPreferences} onChange={setSelectedPreferences} />
      )}

      <SchedultTourWayAndTime
        isCentralizedStage={isCentralizedStage}
        selfGuidTourUnits={selfGuidTourUnits}
        prospectId={prospectId}
        typeList={tourTypeList}
        type={tourType}
        timeZoneInfo={selectedPropertyTimeZone}
        selectedUnits={selectedUnits}
        selectedProperty={selectedProperty}
        onChangeType={setTourType}
        agentInfo={agentInfo}
        onChangeTime={setSelectedAgentTime}
        isReschedule={isReschedule}
        onBackNewUserId={setNewAdminUserId}
        onNoAvailableDay={onNoAvailableDay}
      />
      <ErrorSnackbar setAddTourError={setAddTourError} addTourError={addTourError} />
    </div>
  )
}
