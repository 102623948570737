import React, { FC, useRef, useState } from 'react'
import { Box, Slider, Typography } from '@mui/material'

import indexHooks from './hooks/index'
import { CommonModuleProps, MODULES_CODE_ENUM, QuestionItemProps } from './types.d'

const { stayPlaningHooks } = indexHooks

const StayPlaning = ({ currentIndex }: CommonModuleProps) => {
  const { leaseTerm, onStayPlanTypeChange, showChildrenKey, onLeaseTermChange, stayPlanType, currentData } = stayPlaningHooks(currentIndex)
  const [open, setOpen] = useState(false)
  const anchorEl = useRef<null | HTMLButtonElement>(null)
  const sliderChange = (_: unknown, value: number | number[]) => {
    if (typeof value === 'number') onLeaseTermChange(value)
  }

  return (
    <>
      <Box className="w-full !px-5 !py-4">
        <Typography className="!font-Averta6 !text-lg">
          {currentIndex + 1}. {currentData?.title}
        </Typography>
      </Box>
      <Box className="w-full !px-5 !py-4">
        <div className="w-full grid gap-y-4">
          {currentData?.question?.stayPlan?.map((item: QuestionItemProps) => {
            const active = item.key === stayPlanType
            return (
              <Box
                onClick={() => onStayPlanTypeChange(item.key)}
                className={`${
                  active ? '!border-[#050A22]' : '!border-[#E0E2EF]'
                } flex items-center cursor-pointer justify-start border rounded-xl px-4 py-2.5 `}
                key={item.key}
              >
                <Box className={`w-4 h-4 border rounded-2xl mr-4 ${active ? 'border-[#E74F4F] border-[6px]' : 'border-[#E0E2EF] '}`} />
                <Typography className="!font-Averta4 !text-sm !leading-6">{item.desc}</Typography>
              </Box>
            )
          })}
        </div>

        {showChildrenKey?.includes(MODULES_CODE_ENUM.LEASE_TERM) ? (
          <div className="mt-4">
            <Box
              ref={anchorEl}
              aria-describedby="month-popover"
              onClick={() => {
                setOpen(!open)
              }}
              className="flex bg-[#fff] rounded-xl py-[5px] px-4 justify-between items-center"
            >
              <div className="flex items-start flex-col">
                <span className="text-[#75798D] text-xs font-Averta4 mb-1">Lease Term (Month)</span>
                <span className="text-[#050A22] text-base font-Averta4">
                  {leaseTerm} {leaseTerm === 1 ? 'month' : 'months'}
                </span>
              </div>
            </Box>
            <Slider
              className="mt-3"
              onChange={sliderChange}
              sx={{
                '& .MuiSlider-rail': {
                  bgcolor: '#E0E2EF',
                },
              }}
              step={1}
              value={leaseTerm}
              max={currentData?.question?.stayLeaseTerm?.maxMonth}
              min={currentData?.question?.stayLeaseTerm?.minMonth}
              color="secondary"
            />
          </div>
        ) : null}
      </Box>
    </>
  )
}

export default StayPlaning
