import { FC } from 'react'
import { Box, Slider, Typography } from '@mui/material'

import { generateRentOptions } from '../helpers'
import { RentBudgetField } from '../RentBudgetField'

interface BudgetV4Props {
  budget: [number, number]
  onChange: (budget: [number, number]) => void
}

const BudgetV4: FC<BudgetV4Props> = props => {
  const { budget, onChange } = props
  const sliderChange = (_: unknown, value: number | number[]) => {
    if (Array.isArray(value)) {
      onChange([value[0], value[1]])
    }
  }

  const minBudget = budget[0]
  const maxBudget = budget[1]
  const minRentOptions = generateRentOptions(0, 12000, 100)
  const maxRentOptions = generateRentOptions(minBudget, 12000, 100)

  return (
    <>
      <Box className="w-full !px-5 !py-4">
        <Typography className="!font-Averta6 !text-lg">What&apos;s your monthly budget for rent?</Typography>
      </Box>
      <Box className="w-full !px-5 !py-4">
        <div className="flex justify-center items-center">
          <RentBudgetField
            label="Min"
            options={minRentOptions}
            value={minBudget}
            onChange={(e, newMinBudget) => {
              const newMaxBudget = newMinBudget > maxBudget ? newMinBudget : maxBudget
              sliderChange(e, [newMinBudget, newMaxBudget])
            }}
          />
          <span className="flex-grow text-[#75798D] font-Averta4 text-base text-center">To</span>
          <RentBudgetField
            label="Max"
            options={maxRentOptions}
            value={maxBudget}
            onChange={(e, newMaxBudget) => {
              const newMinBudget = newMaxBudget < minBudget ? newMaxBudget : minBudget
              sliderChange(e, [newMinBudget, newMaxBudget])
            }}
          />
        </div>
        <div className="mt-6">
          <Slider onChange={sliderChange} step={100} value={budget} max={12000} min={0} color="secondary" />
        </div>
      </Box>
    </>
  )
}

export default BudgetV4
