import { SyntheticEvent } from 'react'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import { formatToUSD } from 'src/utils/formatter'

type RentBudgetFieldProps = {
  label: string
  options: number[]
  value?: number
  onChange: (e: SyntheticEvent, option: number) => void
}

function formatOption(option: string | number) {
  return typeof option === 'string' ? parseInt(option, 10) : option
}

const MAX_RENT = 12000

export function RentBudgetField({ label, options, value, onChange }: RentBudgetFieldProps) {
  return (
    <Autocomplete
      freeSolo
      className="w-2/5"
      disableClearable
      disablePortal
      options={options.map(String)}
      renderOption={(props, option) => (
        <li {...props} key={option}>
          {formatToUSD(formatOption(option))}
        </li>
      )}
      value={value?.toString()}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          value={value}
          type="number"
          InputProps={{
            ...params.InputProps,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const input = event.target.value
            const newValue = parseInt(input, 10)

            onChange(event, newValue > MAX_RENT ? 0 : newValue)
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderRadius: '8px',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#1f2937',
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#1f2937',
            },
          }}
        />
      )}
      onChange={(event: React.SyntheticEvent, option: string | number) => {
        const newValue = formatOption(option)
        onChange(event, newValue)
      }}
    />
  )
}
