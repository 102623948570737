import { isFinite } from 'lodash'

/**
 * 获取欧美价格字符串
 * @param value price
 * @returns
 */
export const getCurrency = (value: string) => {
  const numberValue = parseFloat(value)
  if (!isFinite(numberValue)) {
    return ''
  }

  return numberValue.toLocaleString('en-US')
}

export const initPageConfig = (version: number) => {
  const defaultConfig = {
    title: 'Renty Survey',
    icon: 'https://tripalink-public.s3-accelerate.amazonaws.com/web%2Fdfbc289a9e4bd96c.png',
  }
  const versionConfig = {
    5: {
      title: 'Renty Survey',
      icon: 'https://tripalink-public.s3-accelerate.amazonaws.com/web%2Fdfbc289a9e4bd96c.png',
    },
  }[version]

  document.title = (versionConfig || defaultConfig)?.title || ''
  const favicon: any = document.querySelector('link[rel="icon"]')
  if (favicon && favicon?.href) {
    favicon.href = (versionConfig || defaultConfig)?.icon
  }
}
