import React, { FC, useEffect, useMemo } from 'react'
import { Box, Chip, Typography } from '@mui/material'
import { last, sortBy } from 'lodash'

interface FloorPlanV4Props {
  bedroomList: { key: string; value: string }[]
  bathroomList: { key: string; value: string }[]
  selectBedroom: string[]
  selectBathroom: string[]
  onChange: (type: 'bedroom' | 'bathroom', list: string[]) => void
}
const FloorPlanV4: FC<FloorPlanV4Props> = props => {
  const { bedroomList, bathroomList, selectBedroom, selectBathroom, onChange } = props
  const onClick = (type: 'bedroom' | 'bathroom', val: string) => {
    const tempList = [...(type === 'bedroom' ? selectBedroom : selectBathroom)]
    if (tempList.includes(val)) {
      tempList.splice(tempList.indexOf(val), 1)
    } else {
      tempList.push(val)
    }
    onChange(type, tempList)
  }
  const computedBathRoom = () => {
    const maxBathRoom = last(sortBy(selectBedroom))
    const newSelectBathroom = selectBathroom.filter(val => Number(val) <= Number(maxBathRoom) + 1)
    onChange('bathroom', newSelectBathroom)
  }

  const maxSelectBathRoom = useMemo(() => {
    const root = last(sortBy(selectBedroom, r => Number(r)))
    return Number.isNaN(Number(root)) ? -1 : Number(root)
  }, [selectBedroom])

  useEffect(() => {
    computedBathRoom()
  }, [selectBedroom])
  return (
    <Box className="w-full !px-5 !py-4 grid grid-cols-1 gap-y-8">
      <Box>
        <Typography className="!font-Averta6 !text-lg">What type of room are you looking for?</Typography>
        <Typography className="!font-Averta4 !text-sm !mt-2 text-[#75798D]">
          If you are unsure, you can select multiple types. We will help you narrow it down.
        </Typography>
      </Box>
      <Box>
        <Typography className="!font-Averta6 !text-base">Number of bedrooms</Typography>
        <Box className="grid gap-y-2 gap-x-4 mt-4 grid-cols-[repeat(auto-fill,100px)]">
          {bedroomList.map(item => (
            <Chip
              onClick={() => {
                onClick('bedroom', item.key)
              }}
              className={`w-[100px] !py-1.5 ${
                selectBedroom.some(val => val === item.key) ? '!border-[#050A22] !bg-[#F7F9FC]' : '!border-[#E0E2EF]'
              } !font-Averta4 !text-sm`}
              label={item.value}
              variant="outlined"
              key={item.key}
            />
          ))}
        </Box>
      </Box>
      <Box>
        <Typography className="!font-Averta6 !text-base">Number of bathrooms</Typography>
        <Box className="grid gap-y-2 gap-x-4 mt-4 grid-cols-[repeat(auto-fill,100px)]">
          {bathroomList.map(item => (
            <Chip
              disabled={Number(item.key) > Number(maxSelectBathRoom) + 1}
              onClick={() => {
                onClick('bathroom', item.key)
              }}
              className={`w-[100px] !py-1.5 ${
                selectBathroom.some(val => val === item.key) ? '!border-[#050A22] !bg-[#F7F9FC]' : '!border-[#E0E2EF]'
              } !font-Averta4 !text-sm`}
              label={item.value}
              variant="outlined"
              key={item.key}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default FloorPlanV4
