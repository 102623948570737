import React, { FC, PropsWithChildren, useLayoutEffect } from 'react'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { Box, Typography } from '@mui/material'

import CheckIcon from '../../../../assets/imgs/checkIcon.svg'
import Logo from '../../../../assets/imgs/rentyWhiteIcon.svg'
import SubmitResult from '../submitResult'

interface PCV5props {
  moduleList: { key: string; component: JSX.Element }[]
  disabled: boolean
  loading: boolean
  onSubmit: () => void
  isSubmit: boolean
  questionnaireId: string
}

const PCV5: FC<PCV5props> = props => {
  const { moduleList, disabled, loading, onSubmit, isSubmit, questionnaireId } = props
  useLayoutEffect(() => {
    document.body.style.backgroundColor = '#f7f9fc'
  }, [])
  return (
    <div>
      <div className="bg-[#111212] sticky z-20 top-0  px-10 h-16 flex items-center justify-start">
        <img src={Logo} className="h-6 text-[red]" alt="tripalink" />
        <div className="bg-[white] mx-8 h-5 w-[1px] border-box" />
        <Typography className="!font-Averta6 !text-lg !text-[white]">Rental Preference Survey</Typography>
      </div>
      <div className="min-h-[calc(100vh-64px)] w-1/2 m-auto bg-white px-[100px] pt-12 pb-10">
        {isSubmit ? (
          <Box className="pt-[200px]">
            <SubmitResult questionnaireId={questionnaireId} isPC />
          </Box>
        ) : (
          <>
            <div className="p-4 bg-[#F7F9FC] rounded flex  items-start">
              <img src={CheckIcon} className="w-4  mr-2 mt-0.5" alt="" />
              <span className="font-[400] text-sm">
                Please answer the following 5 questions so we can find the right home for your needs. All questions are required.
              </span>
            </div>
            {moduleList.map((module, index) => (
              <ItemContainer key={module.key} index={index + 1}>
                {module.component}
              </ItemContainer>
            ))}
            <LoadingButton
              className={`${
                disabled || loading ? '!bg-[#e0e0e0] cursor-not-allowed' : '!bg-[#E74F4F] cursor-pointer'
              }  !rounded-xl !px-[35px] !py-3`}
              onClick={onSubmit}
              loading={loading}
              disabled={disabled || loading}
            >
              <Typography className="capitalize !font-Averta6 !text-base text-white">Submit</Typography>
            </LoadingButton>
          </>
        )}
      </div>
    </div>
  )
}

// eslint-disable-next-line react/require-default-props
const ItemContainer: FC<PropsWithChildren<{ index: number }>> = props => {
  const { children, index } = props
  return (
    <div className="pb-[73px] relative">
      <Typography className="!absolute top-4 left-[-9px] !text-lg !font-Averta6">{index}. </Typography>
      {children}
    </div>
  )
}
export default PCV5
