/* eslint-disable react-hooks/rules-of-hooks */
import { useRoutes } from 'react-router-dom'

import Form from '../components/form/versions'
import ScheduleTour from '../components/scheduleTour'
import ScheduleTourV2 from '../components/scheduleTourV2'

const getAuthRoutes = () =>
  useRoutes([
    {
      path: '/form',
      element: <Form />,
      children: [],
    },
    {
      path: '/scheduleTour/:questionnaireId/:signature/:organizationId/:version/:channel',
      element: <ScheduleTour />,
    },
    {
      path: '/scheduleTourV2/:questionnaireId/:signature/:organizationId/:version/:channel',
      element: <ScheduleTourV2 />,
    },
  ])

const AuthRoutes = () => getAuthRoutes()
export default AuthRoutes
