import React, { FC } from 'react'
import { RangePicker as ReactRangePicker } from 'react-trip-date'
import { Box, Typography } from '@mui/material'
import moment from 'moment'

interface MoveDateV4Props {
  moveInDate: { from: string; to: string }
  onChange: (moveInDate: { from: string; to: string }) => void
}
const MoveDateV4: FC<MoveDateV4Props> = props => {
  const { moveInDate, onChange } = props
  return (
    <>
      <Box className="w-full !px-5 !py-4">
        <Typography className="!font-Averta6 !text-lg">When will you move in ?</Typography>
        <Typography className="!font-Averta4 !text-sm !mt-2 text-[#75798D]">
          Even if you&apos;re not exactly sure, try and give us an estimate so we can show you what&apos;s available.
        </Typography>
      </Box>
      <Box className="w-full !px-5 !py-4 min-h-[378px]">
        <ReactRangePicker
          components={{
            header: {
              format: `MMMM YYYY`,
            },
          }}
          numberOfMonths={1}
          autoResponsive={false}
          initialMonthAndYear={moveInDate.from || moment()?.format('YYYY-MM-DD')}
          onChange={onChange}
          disabledBeforeToday
          selectedDays={{ from: moveInDate.from, to: moveInDate.from }}
        />
      </Box>
    </>
  )
}

export default MoveDateV4
