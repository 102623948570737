import React, { useRef, useState } from 'react'
import { Box, Slider, Typography } from '@mui/material'

import indexHooks from './hooks/index'
import { CommonModuleProps } from './types.d'

const { leaseTermHooks } = indexHooks

const LeaseTerm = ({ currentIndex }: CommonModuleProps) => {
  const { leaseTerm, onChange, currentData } = leaseTermHooks(currentIndex)
  const [open, setOpen] = useState(false)
  const anchorEl = useRef<null | HTMLButtonElement>(null)
  const sliderChange = (_: unknown, value: number | number[]) => {
    if (typeof value === 'number') onChange(value)
  }

  return (
    <>
      <Box className="w-full !px-5 !py-4">
        <Typography className="!font-Averta6 !text-lg">
          {currentIndex + 1}. {currentData?.title}
        </Typography>
      </Box>
      <Box className="w-full !px-5 !py-4">
        <Box
          ref={anchorEl}
          aria-describedby="month-popover"
          onClick={() => {
            setOpen(!open)
          }}
          className="flex bg-[white] rounded-xl py-[5px] px-4 justify-between items-center"
        >
          <div className="flex items-start flex-col">
            <span className="text-[#75798D] text-xs font-Averta4 mb-1">Lease Term (Month)</span>
            <span className="text-[#050A22] text-base font-Averta4">
              {leaseTerm} {leaseTerm === 1 ? 'month' : 'months'}
            </span>
          </div>
        </Box>
        <Slider
          className="mt-3"
          onChange={sliderChange}
          sx={{
            '& .MuiSlider-rail': {
              bgcolor: '#E0E2EF',
            },
          }}
          step={1}
          value={leaseTerm}
          max={currentData?.question?.leaseTerm?.maxMonth}
          min={currentData?.question?.leaseTerm?.minMonth}
          color="secondary"
        />
      </Box>
    </>
  )
}

export default LeaseTerm
