import React, { useContext, useEffect, useMemo } from 'react'
import { cloneDeep, last, sortBy } from 'lodash'

import contextData from '../contextData'

const FloorPlanHooks = (currentIndex: number) => {
  const { moduleList, dataCollect, updateDataCollectSubmitValueFunction, mergeDataCollectFunction } = useContext(contextData)
  const currentData = moduleList[currentIndex]
  // bedroom origin data
  const bedroomList = useMemo(() => moduleList[currentIndex]?.question?.bedroom, [currentIndex, moduleList]) || []
  // bathroom origin data
  const bathroomList = useMemo(() => moduleList[currentIndex]?.question?.bathroom, [currentIndex, moduleList]) || []
  // the selected bedrooms list
  const selectBedroom = useMemo(() => dataCollect?.[currentIndex]?.submitValue?.[0]?.value as string[], [dataCollect, currentIndex]) || []
  // the selected bathrooms list
  const selectBathroom = useMemo(() => dataCollect?.[currentIndex]?.submitValue?.[1]?.value as string[], [dataCollect, currentIndex]) || []
  // current module can next
  const isCanNext = useMemo(() => selectBedroom?.length > 0, [selectBedroom])
  // mobile display data

  const displayData = useMemo(() => {
    let str = ''
    const tempBedroomList = bedroomList?.filter((item: { key: string }) => selectBedroom?.includes(item.key))
    const tempBathroomList = bathroomList?.filter((item: { key: string }) => selectBathroom?.includes(item.key))
    const displayBedSuffix = tempBedroomList?.length > 1 || (tempBedroomList?.length === 1 && tempBedroomList[0]?.key !== '0')
    const displayBedSemicolon = tempBathroomList?.length > 0
    str = `${tempBedroomList.map((i: { value: string }) => i.value).join(', ')}${displayBedSuffix ? ' bed(s)' : ''}${
      displayBedSemicolon ? ';' : ''
    }`
    if (tempBathroomList.length > 0) {
      str += `${tempBathroomList.map((i: { value: string }) => i.value).join(',')} bath;`
    }

    return str
  }, [selectBedroom, selectBathroom])

  const onSelectBedroom = (value: string) => {
    const tempList = cloneDeep(selectBedroom)
    if (tempList.includes(value)) {
      tempList.splice(tempList.indexOf(value.toString()), 1)
    } else {
      tempList.push(value.toString())
    }
    const maxBathRoom = last(sortBy(tempList))
    const newSelectBathroom = selectBathroom.filter(val => Number(val) <= Number(maxBathRoom))

    if (mergeDataCollectFunction) {
      mergeDataCollectFunction(currentIndex, {
        submitValue: [
          {
            name: dataCollect?.[currentIndex]?.submitValue?.[0]?.name,
            value: tempList,
          },
          {
            name: dataCollect?.[currentIndex]?.submitValue?.[1]?.name,
            value: newSelectBathroom,
          },
        ],
      })
    }
  }

  const onSelectBathroom = (value: string, valueList?: string[]) => {
    if (valueList && updateDataCollectSubmitValueFunction) {
      updateDataCollectSubmitValueFunction(currentIndex, 1, valueList)
      return
    }
    const tempList = cloneDeep(selectBathroom)
    if (tempList.includes(value)) {
      tempList.splice(tempList.indexOf(value.toString()), 1)
    } else {
      tempList.push(value.toString())
    }
    // 更新dataCollect
    if (updateDataCollectSubmitValueFunction) {
      updateDataCollectSubmitValueFunction(currentIndex, 1, tempList)
    }
  }

  const maxSelectBathRoom = useMemo(() => {
    const newSelectBedRoomList = selectBedroom?.map(item => (item === 'Studio' ? 0 : item))
    const root = last(sortBy(newSelectBedRoomList, r => Number(r)))
    return Number.isNaN(Number(root)) ? -1 : Number(root)
  }, [selectBedroom])

  useEffect(() => {
    if (mergeDataCollectFunction) {
      mergeDataCollectFunction(currentIndex, {
        isCanNext,
        displayData,
      })
    }
  }, [isCanNext, displayData])

  return {
    currentData,
    bathroomList,
    bedroomList,
    selectBathroom,
    selectBedroom,
    isCanNext,
    onSelectBedroom,
    onSelectBathroom,
    updateDataCollectSubmitValueFunction,
    maxSelectBathRoom,
  }
}

export default FloorPlanHooks
