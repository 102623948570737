import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

interface ErrorSnackbarProps {
  addTourError: {
    open: boolean
    message: string
  }
  setAddTourError: (val: { open: boolean; message: string }) => void
}
const ErrorSnackbar = ({ setAddTourError, addTourError }: ErrorSnackbarProps) => {
  const handleClose = () => {
    setAddTourError({
      open: false,
      message: '',
    })
  }

  return (
    <Snackbar
      open={addTourError?.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert severity="error" sx={{ width: '100%', '& .MuiAlert-message': { fontSize: '16px' } }}>
        {addTourError?.message}
      </Alert>
    </Snackbar>
  )
}

export default ErrorSnackbar
