import React, { FC, useMemo, useRef, useState } from 'react'
import { Box, Button, Drawer, Stack } from '@mui/material'
import { Statsig } from 'statsig-react'

import Back from '../../icons/back'
import Down from '../../icons/down'
import { checkIsMobile } from '../../utils/agent'
import PropertyCard from '../scheduleTourV2/propertyCard'
import { PageTypeType, ScheduleTourPropertyType } from './contantsAndType'

interface ScheduleTourHeaderProps {
  showMenu: boolean
  pageType: PageTypeType
  theme: 'white' | 'black'
  // eslint-disable-next-line react/require-default-props
  showBack?: boolean
  // eslint-disable-next-line react/require-default-props
  onBack?: () => void
  selected: string
  // eslint-disable-next-line react/require-default-props
  onChange?: (propertyId: string) => void
  list: ScheduleTourPropertyType[]
  fixedDom: boolean
  title?: string
}

const isMobile = checkIsMobile()

const ScheduleTourHeader: FC<ScheduleTourHeaderProps> = props => {
  const { onBack, fixedDom, showBack = false, pageType, selected, list, showMenu, theme = 'white', onChange, title } = props
  const anchorEl = useRef<null | HTMLDivElement>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const selectedName = useMemo(() => {
    if (title) {
      return title
    }
    const target = list.find(item => item.id === selected)
    return target?.name ?? 'Unknown'
  }, [list, selected])
  const onBackClick = () => {
    if (onBack) {
      Statsig.logEvent('schedule_tour_header_back', window.location.href, {
        propertyId: selected,
        pageType,
      })
      onBack()
    }
  }
  const onChangeClick = (propertyId: string) => {
    if (onChange) {
      Statsig.logEvent('schedule_tour_header_change', window.location.href, {
        propertyId,
        pageType,
      })
      onChange(propertyId)
    }
  }
  const showMenuContent = () => {
    if (!showMenu || menuOpen) return
    setMenuOpen(true)
  }
  const closeMenuContent = (item: { id: string }) => {
    if (showMenu) {
      onCloseDrawer()
      onChangeClick(item.id)
    }
  }
  const onCloseDrawer = () => {
    setMenuOpen(false)
  }

  return (
    <Box className={`${fixedDom ? 'lg:fixed lg:top-16 lg:max-w-[50%] bg-white z-20  left-[25%]' : 'bg-transparent'} w-full  z-10`}>
      <Box className="flex items-center p-3">
        <Box className="w-1/5">
          {showBack && (
            <Box onClick={onBackClick} className="flex cursor-pointer items-center justify-start">
              <Back className="!w-6 !h-6" htmlColor={theme === 'black' || fixedDom ? '#050A22' : '#fff'} />
            </Box>
          )}
        </Box>
        <Box ref={anchorEl} className="flex-grow flex justify-center items-center cursor-pointer">
          <Box
            aria-describedby="property-menu"
            onClick={showMenuContent}
            className={`font-Averta6 lg:text-base text-lg text-[${theme === 'black' || fixedDom ? '#050A22' : '#fff'}] text-center`}
          >
            {selectedName}
          </Box>
          {showMenu && (
            <Down
              className="!w-6 !h-6 cursor-pointer"
              color="inherit"
              htmlColor={theme === 'black' || fixedDom ? '#75798D' : '#fff'}
              onClick={() => {
                if (menuOpen) return
                setMenuOpen(true)
              }}
            />
          )}
          <Drawer
            open={menuOpen}
            onClose={onCloseDrawer}
            anchor="bottom"
            // swipeAreaWidth={56}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              '.MuiPaper-root': {
                width: !isMobile ? '50%' : '100%',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                top: '48px',
                left: !isMobile ? '25%' : '0',
              },
            }}
          >
            <Stack justifyContent="center" direction="row" className="bg-white py-2">
              <Box className="h-1 w-[50px] rounded-sm bg-neutral-300" />
            </Stack>
            <Box className="h-full overflow-y-auto">
              <div className="grid grid-cols-1 gap-y-2 px-5 pb-5">
                {list.map(property => (
                  <PropertyCard
                    key={property.id}
                    property={property as any}
                    onClick={() => {
                      closeMenuContent(property)
                    }}
                  />
                ))}
              </div>
            </Box>
            <Box className="px-5 pb-4">
              <Button
                onClick={onCloseDrawer}
                sx={{
                  textTransform: 'none',
                }}
                className=" bg-red-450 text-white text-base w-full rounded-lg"
              >
                Done
              </Button>
            </Box>
          </Drawer>
        </Box>
        <Box className="w-1/5" />
      </Box>
    </Box>
  )
}

export default ScheduleTourHeader
