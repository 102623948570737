import { createTheme } from '@mui/material/styles'

export const moduleList = [
  {
    code: 'floorPlan',
    title: 'What type of room are you looking for?',
    desc: 'If you are unsure, you can select multiple types. We will help you narrow it down.',
    thumbnailLabel: 'Floor Plan',
    question: {
      bedroom: [
        {
          key: '0',
          value: 'Studio',
          desc: 'Studio',
        },
        {
          key: '1',
          value: '1',
          desc: '1 beds',
        },
        {
          key: '2',
          value: '2',
          desc: '2 beds',
        },
        {
          key: '3',
          value: '3',
          desc: '3 beds',
        },
        {
          key: '4',
          value: '4',
          desc: '4 beds',
        },
        {
          key: '5',
          value: '5',
          desc: '5 beds',
        },
        {
          key: '6',
          value: '6',
          desc: '6+ beds',
        },
      ],
      bedroomResult: 'bedroom',
      bathroom: [
        {
          key: '1',
          value: '1',
          desc: '1 bath',
        },
        {
          key: '2',
          value: '2',
          desc: '2 bath',
        },
        {
          key: '3',
          value: '3',
          desc: '3 bath',
        },
        {
          key: '4',
          value: '4',
          desc: '4 bath',
        },
        {
          key: '5',
          value: '5',
          desc: '5 bath',
        },
        {
          key: '6',
          value: '6',
          desc: '6+ bath',
        },
      ],
      bathroomResult: 'bathroom',
    },
  },
  {
    code: 'budget',
    title: "What's your monthly budget for rent?",
    thumbnailLabel: 'Budget',
    question: {
      maxBudget: 12000,
      minBudget: 0,
      maxBudgetResult: 'maxBudget',
      minBudgetResult: 'minBudget',
    },
  },
  {
    code: 'moveInDate',
    title: 'When will you move in?',
    desc: "Even if you're not exactly sure, try and give us an estimate so we can show you what's available.",
    thumbnailLabel: 'Move-in Date',
    question: {
      moveInDateResult: 'moveInDate',
    },
  },
  {
    code: 'creditScore',
    title: 'What is your credit score?',
    thumbnailLabel: 'Credit Score',
    question: {
      creditScore: [
        {
          key: 'GREATER_EQ',
          value: 'GREATER_EQ',
          desc: 'Greater than or equal to 650',
        },
        {
          key: 'LESS',
          value: 'LESS',
          desc: 'Less than 650',
          childrenKey: ['isStudent', 'cosigner'],
        },
        {
          key: 'UNKNOWN',
          value: 'UNKNOWN',
          desc: "I don't know",
          childrenKey: ['cosigner'],
        },
      ],
      creditScoreResult: 'creditScore',
      isStudent: {
        desc: 'I am a student',
      },
      isStudentResult: 'isStudent',
      cosigner: {
        title: 'Do you have a co-signer?',
      },
      cosignerResult: 'cosigner',
    },
  },
  {
    code: 'leaseTerm',
    title: 'How long are you planning to stay?',
    thumbnailLabel: 'LeaseTerm',
    question: {
      leaseTerm: {
        minMonth: 1,
        maxMonth: 24,
      },
      leaseTermResult: 'leaseTerm',
    },
  },
  {
    code: 'stayPlaning',
    thumbnailLabel: 'Stay Planing',
    title: 'How long are you planning to stay?',
    question: {
      stayPlan: [
        {
          key: 'SPRING_QUARTER_OR_SEMESTER',
          value: 'SPRING_QUARTER_OR_SEMESTER',
          desc: 'Spring quarter or semester',
        },
        {
          key: 'SUMMER_QUARTER_OR_SEMESTER',
          value: 'SUMMER_QUARTER_OR_SEMESTER',
          desc: 'Summer quarter or semester',
        },
        {
          key: 'FALL_QUARTER_OR_SEMESTER',
          value: 'FALL_QUARTER_OR_SEMESTER',
          desc: 'Fall quarter or semester',
        },
        {
          key: 'WINTER_QUARTER_OR_SEMESTER',
          value: 'WINTER_QUARTER_OR_SEMESTER',
          desc: 'Winter quarter or semester',
        },
        {
          key: 'STAY_LEASE_TERM',
          value: 'STAY_LEASE_TERM',
          desc: 'Custom months',
          childrenKey: ['leaseTerm'],
        },
      ],
      stayPlanResult: 'stayPlan',
      stayLeaseTerm: {
        minMonth: 1,
        maxMonth: 24,
      },
      stayLeaseTermResult: 'leaseTerm',
    },
  },

  {
    code: 'parkingSpot',
    title: 'What is the minimum number of parking spots you need?',
    desc: 'Please note that there is no guarantee of securing these spots.',
    thumbnailLabel: 'Parking spots',
    question: {
      parkingSpot: [
        {
          key: 'DONT_NEED_PARKING_SPOT',
          value: '0',
          desc: "I don't need a parking spot",
        },
        {
          key: 'NEED_1_PARKING_SPOT',
          value: '1',
          desc: '1 spot',
        },
      ],
      parkingSpotResult: 'parkingSpot',
    },
  },
]

export const theme = createTheme({
  palette: {
    secondary: {
      main: '#050A22',
    },
    primary: {
      main: '#E74F4F',
    },
  },
})
