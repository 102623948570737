import React from 'react'

import Budget from './budget'
import CreditScore from './creditScore'
import FloorPlan from './floorplan'
import LeaseTerm from './leaseTerm'
import MoveDate from './moveDate'
import Parking from './parking'
import StayPlaning from './stayPlaning'
import { MODULES_CODE_ENUM } from './types.d'

interface ComponentMap {
  [key: string]: React.FC<{ currentIndex: number }>
}

const componentMap: ComponentMap = {
  [MODULES_CODE_ENUM.FLOOR_PLAN]: FloorPlan,
  [MODULES_CODE_ENUM.BUDGET]: Budget,
  [MODULES_CODE_ENUM.MOVE_IN_DATE]: MoveDate,
  [MODULES_CODE_ENUM.CREDIT_SCORE]: CreditScore,
  [MODULES_CODE_ENUM.STAY_PLANING]: StayPlaning,
  [MODULES_CODE_ENUM.LEASE_TERM]: LeaseTerm,
  [MODULES_CODE_ENUM.PARKING]: Parking,
}

const ItemRender = ({ code, index }: { code: string; index: number }) => {
  if (!code || !componentMap[code]) {
    return null
  }

  const SelectedComponent = componentMap[code]
  return <SelectedComponent currentIndex={index} />
}

export default ItemRender
